<div class="page-title-area-portfolio">
    <div class="container">
        <div class="page-title-content">
           <div class="row">
            <div class="col-12 col-lg-8">
                <span class="fs-4 text-white">DECOUVREZ NOTRE</span>
                <h2>services de developpement </h2>
                <h2 class="mt-4"><span class="p-3 fw-bold" style="background:#1d42d9;border-radius: 15px;">d'applications de bureaux</span></h2>
                <br>
                <p>Transformez vos idées en réalité grâce à nos services experts en développement d’applications de bureaux. Nous sommes spécialisés dans la création de solutions innovantes qui stimulent la croissance et engagent efficacement les utilisateurs.</p>
                <button class="btn btn-lg btn-primary d-lg-none" [routerLink]="['/project-form']">parlez nous de votre projet</button>
            </div>
            <div class="col-12 col-lg-4 d-none d-lg-inline">
                <div class="card" style="border-radius: 25px;">
                    <div class="card-body" >
                        <h4 class="card-title fs-4" style="color:#000;">Discutons de votre projet web</h4>
                        <app-contact-form></app-contact-form>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>


<section class="home-company-area">
    <div class="container-md">
        <div class="row align-items-center" style="padding-bottom:120px;">
            <div class="col-lg-7 col-xxl-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>developpement d'applications de bureaux</span>
                        <h2>Pourquoi une application de bureaux pour votre projet ?</h2>
                        <p>Les applications de bureaux offrent des performances optimales et une stabilité accrue, idéales pour des tâches complexes et intensives. Elles permettent une intégration poussée avec le système d'exploitation, offrant des fonctionnalités avancées et une meilleure utilisation des ressources matérielles. Contrairement aux applications web, elles peuvent fonctionner hors ligne, garantissant une accessibilité continue. Pour les entreprises nécessitant des outils puissants et personnalisés, une application de bureaux peut offrir une solution robuste et efficace, adaptée à des besoins spécifiques et à une gestion fluide des données.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-xxl-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/desk.jpg" style="border-radius:20px;" alt="company" />
                </div>
            </div>
        </div><!--item1-->
        <div class="row align-items-center mb-3">
            <div class="col-lg-5 col-xxl-6 col-md-12">
                <div class="company-img-2">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
            <div class="col-lg-7 col-md-12 col-xxl-6">
                <div class="company-content">
                    <div class="company-tittle">
                        <span class="pb-span">Problèmes</span>
                        <h4>Problèmes auxquels vous êtes confronté avec la plupart des sociétés de développement</h4>
                        <p>Il est crucial de choisir le bon partenaire de développement  pour votre projet. Une entreprise de conception de d'applications de bureaux inadaptée peut engendrer de nombreux problèmes.</p>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="py-2"><img src="../../../../../assets/images/svg/budget2.svg" alt=""> <span class="px-2 pb-text">Budget excessif</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/database.svg" alt=""> <span class="px-2 pb-text">Données non sécurisée</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/communication.svg" alt=""> <span class="px-2 pb-text">Mauvaises communications</span></div>
                            </div>
                            
                            <div class="col-12 col-sm-6">
                                <div class="py-2"><img src="../../../../../assets/images/svg/quality.svg" alt=""> <span class="px-2 pb-text">Aucune assurance qualité</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/doc.svg" alt=""> <span class="px-2 pb-text">Manque de documentation</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/box.svg" alt=""> <span class="px-2 pb-text">Livrables retardés</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 2-->
    </div>
</section>

<!-- NYANGOO AGENCY HELP -->
<section class="help">
    <div class="container-md">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Comment Nyangoo company peut vous aider</h2>
            <p>Chez Nyangoo Agency, nous comprenons les défis auxquels vous pouvez être confrontés lors du choix d'un partenaire de développement web. C'est pourquoi nous nous engageons à offrir des solutions qui éliminent ces problèmes courants. Notre approche garantit une gestion budgétaire transparente et contrôlée, en veillant à ce que vos projets respectent vos contraintes financières.</p>
        </div>

       <div class="row row-cols-2 row-cols-sm-3">
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
           <div class="text-center">
            <img src="../../../../../assets/images/svg/feee-consultation.svg" class="pb-2 pt-4" alt="">
            <p style="color: #000;" class="fs-6 pt-2 pb-4">30 min de Consultation gratuite</p>
           </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/sign-nda.svg"class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">accord de confidentialité</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/dedicated-developer.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Développeurs et concepteurs internes</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/data-security.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Securite des donnees</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/feedback-cycle.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Interaction avec le client</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/regular-update.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Mise a jour reguliere</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/complate-transprency.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Transparence complte</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/quality-assurance.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Assurance qualite</p>
               </div>
        </div>
        <div class="col" >
            <div class="text-center">
                <img src="../../../../../assets/images/svg/maintanance-support.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Maintenance et support</p>
               </div>
        </div>
       </div>
    </div>
</section>


<!-- WEB SERVICES -->
<section id="web-services" class="web-services pt-5">
    <div class="container-lg">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Nos Services</h2>
            <p>Nous créons des applications attrayants et fonctionnels pour  votre entreprise.</p>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-4">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Developpement d'applications window</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Nous créons des applications Windows performantes et intuitives, adaptées à vos besoins spécifiques. Notre expertise permet une intégration complète avec les fonctionnalités natives de Windows, garantissant sécurité et efficacité. Que ce soit pour des outils de productivité ou des solutions d'entreprise, notre équipe transforme vos idées en applications fiables et puissantes.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Developpement d'applications Mac</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Nous concevons des applications Mac performantes et intuitives, parfaitement adaptées à vos besoins. Grâce à notre expertise, nous exploitons les fonctionnalités natives de macOS pour garantir une sécurité optimale et une expérience utilisateur fluide. Notre équipe transforme vos idées en applications Mac fiables et puissantes.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Developpement d'applications multiplateformes</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Nous créons des applications multiplateformes performantes et intuitives, adaptées à Windows, macOS, et Linux. Notre expertise permet de développer des solutions unifiées qui offrent une expérience utilisateur cohérente sur toutes les plateformes. Nous transformons vos idées en applications fiables et puissantes, accessibles partout.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Automatisation des Processus</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Nous développons des solutions d'automatisation des processus pour améliorer l'efficacité et réduire les tâches répétitives. Nos applications automatisent les flux de travail, optimisent les opérations et augmentent la productivité. Que ce soit pour la gestion des données, l'automatisation des rapports, ou d'autres tâches métiers, nous transformons vos besoins en solutions automatisées, fiables et puissantes.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Support et Maintenance</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Nous offrons des services complets de support et maintenance pour assurer le bon fonctionnement de vos applications. Notre équipe dédiée fournit des mises à jour régulières, résout les problèmes rapidement et optimise les performances. Que ce soit pour des correctifs de sécurité, des améliorations fonctionnelles, ou une assistance technique continue, nous garantissons la fiabilité et la pérennité de vos solutions logicielle.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>

<!-- TECHNOLOGIES STACK -->
<section id="technologies" class="technologies py-5">
    <div class="container-lg">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Nos technologie</h2>
            <p>Ces technologies couvrent une large gamme de besoins et de préférences en développement d'application de bureaux, Nous permettant de choisir les outils les mieux adaptés à vos projets.</p>
        </div>
        <div class="row">
            <div class="col-12 col-sm-3 col-md-2" style="color:#000;"><h3 class="fs-3">Language</h3></div>
            <div class="col-12 col-sm-9 col-md-10">
                <div class="row gy-2 py-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/c-plusplus.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">C++</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/cc.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">C#</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/java.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Java</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/python.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Python</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/swift.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Swift</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/flutter.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Flutter</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
        <hr>
        <div class="row py-3">
            <div class=" col-12 col-sm-3 col-md-2" style="color:#000;"><h3 class="fs-3">Framework</h3></div>
            <div class="col-12 col-sm-9 col-md-10">
                <div class="row gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/net.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">.NET</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/qt.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Qt</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/electron.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Electron</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
        <hr>
        <div class="row py-3">
            <div class="col-12 col-sm-5 col-md-4 col-xl-3" style="color:#000;"><h3 class="fs-3">Bases de Données</h3></div>
            <div class="col-12 col-sm-7 col-md-8 col-xl-9">
                <div class="row gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/MySQL.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">MySQL</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Postgresql_elephant.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">PostgreSQL</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/MongoDB.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">MongoDB</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/firebase.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Firebase</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
    </div>
</section>

<!-- TRUSTED APP DEVELOPPEMENT COMPANY IN CAMEROUN -->
<section id="trusted-app" class="trusted-app">
    <div class="container-lg">
        <h2 class="text-center px-md-5 mx-md-5 trusted-company-title">Société de développement d'applications de confiance au Cameroun</h2>
    <br>
    <p class="text-center">Nous sommes fiers d'être une société de développement d'applications de confiance au Cameroun. Avec une équipe de professionnels passionnés et expérimentés, nous offrons des solutions digitales de haute qualité adaptées aux besoins spécifiques de nos clients. Que ce soit pour le développement de sites web, d'applications mobiles ou de logiciels de bureau, nous mettons un point d'honneur à livrer des produits performants, sécurisés et innovants. En travaillant avec nous, vous bénéficiez d'un partenariat basé sur la transparence, l'engagement et la satisfaction client. Faites-nous confiance pour transformer vos idées en solutions digitales efficaces et pérennes.</p>
    <div class="text-center mt-3">
        <img src="../../../../assets/images/windows-app-sec3-1-img.webp" alt="">
    </div>
    </div>
</section><!-- END TRUSTED APP COMPANY-->

<!-- RECENT WEB PROJECT -->
<section id="recent" class="recent py-5">
    <div class="container-lg">
        <div class="text-center pb-4 pb-sm-3 pb-md-5">
            <span class="fs-4" style="text-transform:uppercase;filter: grayscale(100%);font-weight:bold;">Projets récents</span>
            <h1 class="why-choose-title">Quelques projets récents</h1>
            <p style="font-size: 15px;">Découvrez notre portfolio et explorez les projets que nous avons réalisés pour nos clients. Chaque projet reflète notre engagement envers la qualité, l'innovation et la satisfaction client. Que ce soit des sites web, des applications mobiles ou des solutions d'IA, notre vitrine de travail démontre notre expertise et notre capacité à transformer vos idées en réalités digitales. Inspirez-vous de nos succès et imaginez ce que nous pouvons accomplir ensemble.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">Colorfol Digital store</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                </div>
                <h6>Colorfol est une plateforme camerounaise de promotions de la musique africaine partout dans le monde.</h6>
                <button class="btn btn-primary btn-lg"><a href="https://www.store.colorfol.com/home" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/colorfol.png" alt="">
            </div>
        </div> <!--item 1-->
        <hr>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/oneboutik.png" alt="">
            </div>
            <div class="col-12 col-md-6" >
                <h2 style="color:#000;">Oneboutik.com</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                </div>
                <!-- <h5 style="color:#000;">Solution web | e-commerce | Angular | Typescript | Javasript | Html | css | Nodejs </h5> -->
                <h6>oneboutik.com est une plateforme de e-commerce centralisant toutes les boutiques local d'une ville au même endroit, permettant aux clients d'acheter en ligne dans leur boutique favorites.</h6>
                <button class="btn btn-primary btn-lg"><a rel="noopener noreferrer" style="color:white;text-decoration: none;">Lire plus sur oneboutik.com <span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
        </div> <!-- item 2-->
        <hr>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">Jookafood</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                </div>
                <h6>jookafood est une application web permettant aux utilisateurs de commander des repas en ligne au Cameroun.</h6>
                <button class="btn btn-primary btn-lg"><a href="https://food.jookatechnologies.com/place-list" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/jookafood.png" alt="">
            </div>
        </div> <!-- item 3-->
    </div>
</section>

<app-testimonials-recent></app-testimonials-recent>

<!-- WEB DEVELOPMENT PROCESS -->
<section id="web-development-process" class="web-development-process pb-5">
    <div class="container-lg">
     <div class="section-title-3">
         <h2>Processus de developpement</h2>
         <p>Chez Nyangoo Agency, notre processus de développement  d'application de bureaux est conçu pour garantir une expérience transparente et efficace. Nous travaillons en étroite collaboration avec vous à chaque étape, de l'analyse initiale à la maintenance continue, pour créer des solutions personnalisées qui répondent à vos besoins spécifiques.</p>
     </div>
     <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-3 pt-4">
         <div class="col">
             <div class="card  process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">01</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Comprehension de vos objectifs</h4>
                     <p class="card-text">Nous démarrons en comprenant vos objectifs, travaillant ensemble pour saisir pleinement vos besoins, attentes et ambitions. Cette phase initiale définit clairement les objectifs du projet, établissant une base solide.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card  process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">02</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Analyse et planification</h4>
                     <p class="card-text">Après avoir compris vos objectifs, nous passons à l'analyse et à la planification. Cette étape détermine la portée du projet, identifie les exigences spécifiques et élabore une stratégie détaillée.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card  process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">03</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Design web</h4>
                     <p class="card-text">Notre équipe de designers crée des interfaces élégantes et intuitives qui captivent vos utilisateurs et sont fonctionnelles. Chaque élément est pensé pour refléter votre identité et offrir une expérience exceptionnelle.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">04</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Code et developpement</h4>
                     <p class="card-text"> Nos développeurs traduisent les concepts de conception en code fonctionnel, en utilisant les meilleures pratiques et les technologies les plus récentes. Nous suivons des normes strictes de qualité et de sécurité pour garantir que votre projet fonctionne de manière optimale et sans faille.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">05</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Test et assurance</h4>
                     <p class="card-text">Notre équipe de testeurs effectue des tests approfondis pour identifier et corriger les éventuels problèmes, en s'assurant que votre projet est entièrement fonctionnel et exempt de bugs. Nous nous engageons à fournir des produits de haute qualité qui répondent à vos besoins.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">06</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Deploiement et maintenance</h4>
                     <p class="card-text">Nous gérons le déploiement de manière professionnelle et efficace. Nous surveillons de près les performances, appliquons les mises à jour nécessaires et répondons à tout problème qui pourrait survenir. Notre objectif est de garantir que votre projet reste fonctionnel, sécurisé et à jour.</p>
                 </div>
             </div>
         </div>
     </div>
    </div>
 </section>


 <!-- FAQ section -->
 <section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title-2">
            <span class="fs-4">FAQ</span>
            <h2>Question fréquemment posée</h2>
            <p>Nous avons gagné la confiance des gens grâce à notre excellent service</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-us></app-contact-us>