import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EmailService } from 'src/app/Services/email.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  send_email_loader = false;

  myForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['',[Validators.required]],
    message: ['',[Validators.required]],
    subject: [''],
  });

  constructor( private fb: FormBuilder,private _emailApi: EmailService, private toast: ToastrService){

  }

  submit(){
    this.send_email_loader = true;
    console.log("Fonction d'envoie du message email");
    let body = {
      Html: 
       `<p>Subjet: ${this.myForm.value.subject}</p>
        <p>Email: ${this.myForm.value.email}</p>
        <p>Name:${this.myForm.value.name}</p>
        <p>phone number:${this.myForm.value.phone}</p>
        <p>Message : ${this.myForm.value.message}</p>
        `,
      Text: `Name:${this.myForm.value.name}. phone: ${this.myForm.value.phone}. message: ${this.myForm.value.message} . Subject: ${this.myForm.value.subject}`,
    }
    if (this.myForm.valid){
      console.log("My form All value",this.myForm.value);
      console.log(this.myForm.value.email);
      this._emailApi.sendMail("contact@nyangooagency.com",this.myForm.value.subject,body);
      setTimeout(() => {
        this.send_email_loader = false;
        this.toast.success('Votre message a été transmis sans aucun problème. Merci beaucoup!', 'Email envoyé!');
      }, 2000);
    }else{
      this.send_email_loader = false;
      this.toast.error('please tosend mail, enter all the required fields', 'Email not sent!');
    }
  }


}
