<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contactez nous</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Contactez</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Email</h3>
                        <p>contact@nyangooagency.com</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Address</h3>
                        <p>Rond point express, Yaounde, Cameroun</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Phone</h3>
                        <p>+237 657 368 904</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-us></app-contact-us>