<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo/agency-blue.svg" style="height:45px;" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+237657368904"><i class="bx bxs-phone-call"></i> +237 657 368 904</a></li>
                        <li><a href="mailto:contact@nyangooagency.com"><i class="bx bxs-envelope"></i> contact@nyangooagency.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="https://www.facebook.com/profile.php?id=61554882189181&mibextid=ZbWKwL" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/nyangooagency?igsh=cmRzdTFnZTNpZjAz" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="https://x.com/nyangoo_agency?t=6Y0jR2MJHxI_SGMRhOi6bA&s=08" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="mailto:contact@nyangooagency.com" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                        <li><a  target="_blank"> <i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo/agency-white.svg" style="height:35px;" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Accueil</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services</a>
                            <ul class="dropdown-menu">

                                <li class="nav-item"><a routerLink="/web-application-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Développement d'applications webs</a></li>
                                
                                <li class="nav-item"><a routerLink="/mobile-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Développement d'applications mobiles</a></li>

                                <li class="nav-item"><a routerLink="/desktop-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Développement d'applications de bureaux</a></li>

                                <li class="nav-item"><a routerLink="/web-application-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Développement frontend</a></li>

                                <li class="nav-item"><a routerLink="backend-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Développement d'api backend</a></li>

                                <li class="nav-item"><a routerLink="/design-services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Conception de design UI/UX</a></li>

                                <li class="nav-item"><a routerLink="/ia-services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Services d'intelligence artificielle</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Portfolio</a>
                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Études de cas</a></li> -->
                                <li class="nav-item"><a routerLink="/portfolio" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Projets récents</a></li>
                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Testimonials</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">à propos</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">à propos de nous</a></li>
                                <!-- <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">L'équipe</a></li> -->
                                <!-- <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Comment nous travaillons</a></li> -->
                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)=" closeNavbar()">Faq</a></li>
                            </ul>
                        </li>
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" (click)=" closeNavbar()">Contact </a></li>
                    </ul>
                </div>
                <div class="nav-btn">
                    <a routerLink="/project-form" (click)=" closeNavbar()" class="box-btn">Parlez nous de votre projet maintenant</a>
                </div>
            </nav>
        </div>
    </div>
</div>