import { Component } from '@angular/core';

@Component({
  selector: 'app-backend-dev',
  templateUrl: './backend-dev.component.html',
  styleUrls: ['./backend-dev.component.scss']
})
export class BackendDevComponent {

  accordionItems = [
    {
        title: "Qu'est-ce qu'une API et pourquoi en ai-je besoin pour mon projet ?",
        content: `Une API (Interface de Programmation d'Applications) permet à différentes applications informatiques de communiquer entre elles de manière sécurisée et efficace. Vous pourriez avoir besoin d'une API pour intégrer des fonctionnalités tierces, partager des données entre applications ou créer des interfaces utilisateur personnalisées.`,
        open: false
    },
    {
        title: " Quels sont les avantages de développer une API sur mesure par rapport à l'utilisation d'API tierces ?",
        content: `Le développement d'une API sur mesure vous permet de créer des fonctionnalités spécifiques à vos besoins et de contrôler entièrement le processus de développement. Cela peut être plus coûteux et prendre plus de temps que d'utiliser des API tierces, mais cela offre une plus grande flexibilité et personnalisation.`,
        open: false
    },
    {
        title: 'Quels types de clients servez-vous ?',
        content: `Nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
        open: false
    },
    {
      title: ' Comment puis-je garantir la sécurité de mon API ?',
      content: `La sécurité de votre API est cruciale. Assurez-vous de mettre en œuvre des protocoles d'authentification et d'autorisation robustes, de surveiller les activités suspectes et de mettre à jour régulièrement vos systèmes pour protéger contre les vulnérabilités.`,
      open: false
    },
    {
        title: 'Offrez-vous des services de maintenance après le déploiement ?',
        content: `Oui, nous proposons des services de maintenance continue pour assurer que votre solution reste à jour, sécurisée et performante.`,
        open: false
    },
    {
      title: 'Comment puis-je tester mon API ?',
      content: `Vous pouvez tester votre API en utilisant des outils de test d'API comme Postman ou Swagger. Assurez-vous de tester les différents scénarios d'utilisation, y compris les cas d'erreurs, pour vous assurer que votre API fonctionne correctement dans toutes les situations.`,
      open: false
  },
];

selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item){
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}
