<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Testimonials</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Testimonials</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-2">
            <h2>Ce Que Disent Nos Clients</h2>
            <p>La satisfaction de nos clients est notre priorité absolue. Découvrez ce qu'ils disent de leur expérience avec Nyangoo agency. Nos clients apprécient notre engagement envers la qualité, notre réactivité et notre capacité à transformer leurs idées en réalités digitales. Leurs témoignages reflètent la confiance et la satisfaction qu'ils ont trouvées en collaborant avec nous. Laissez leurs mots vous convaincre de notre expertise et de notre dévouement à fournir des solutions exceptionnelles.</p>
        </div>
        
        <div class="row">
            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="../../../../assets/images/testimonilas/germes.jfif" style="width:270px;object-fit:cover;" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Saint germes</h2>
                                    <span>Fondateur & CPT</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Travailler avec {{company_name}} a été une expérience incroyable. Leur équipe a parfaitement compris ma vision et a créé un site web qui dépasse toutes mes attentes. Leur attention aux détails et leur professionnalisme m'ont vraiment impressionné. Je les recommande vivement à toute entreprise cherchant à améliorer sa présence en ligne.</p>
                    </div>
                    <div class="text-end">
                        <img src="../../../../assets/images/testimonilas/guillemet.jpg" style="height:60px;" alt="">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="https://media.licdn.com/dms/image/D4E03AQH1vCckr0SWAg/profile-displayphoto-shrink_200_200/0/1691233162874?e=2147483647&v=beta&t=L781fWVvBt3XObYs674wxUTRkVt0SbIL7sg27H0YvfQ" style="width:270px;object-fit:cover;" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Marius TENE</h2>
                                    <span>Entrepreuneur</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>{{company_name}} a joué un rôle clé dans la réussite de notre projet digital. Leur capacité à comprendre nos besoins et à proposer des solutions innovantes a fait toute la différence. Grâce à eux, notre site web est non seulement esthétique, mais aussi extrêmement fonctionnel. Nous continuerons à collaborer avec eux pour nos futurs projets.</p>
                    </div>
                    <div class="text-end">
                        <img src="../../../../assets/images/testimonilas/guillemet.jpg" style="height:60px;" alt="">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/client/anne.png" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Anne Marie</h2>
                                    <span>Fondatrice de Start-Up</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Nous avons fait appel à {{company_name}} pour développer notre application mobile, et nous n'avons pas été déçus. Leur créativité et leur expertise technique ont permis de transformer notre idée en une application fluide et attrayante. Le support continu qu'ils offrent est également un grand plus. Une équipe talentueuse et dévouée !</p>
                    </div>
                    <div class="text-end">
                        <img src="../../../../assets/images/testimonilas/guillemet.jpg" style="height:60px;" alt="">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/simo2.jpeg" style="width:270px;object-fit:cover;" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Steve Simo</h2>
                                    <span>Founder CEO</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>J'ai été impressionnée par l'engagement et le savoir-faire de l'équipe de {{company_name}}. Leur approche centrée sur le client et leur souci du détail ont permis de créer une plateforme web performante et facile à utiliser. Je les recommande sans hésitation.</p>
                    </div>
                    <div class="text-end">
                        <img src="../../../../assets/images/testimonilas/guillemet.jpg" style="height:60px;" alt="">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/fred.jfif" alt="testimonials">
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>FRED KOUAM</h2>
                                    <span>Entrepreuneur</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Travailler avec {{company_name}} sur notre projet commun a été une expérience enrichissante. Leur expertise et leur capacité à s'adapter ont été essentielles pour mener à bien ce projet complexe. La collaboration était fluide et productive, et le résultat final est au-delà de nos attentes. C'est un plaisir de travailler avec une équipe aussi professionnelle et dédiée.</p>
                    </div>
                    <div class="text-end">
                        <img src="../../../../assets/images/testimonilas/guillemet.jpg" style="height:60px;" alt="">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 d-none">
                <div class="single-testimonials">
                    <div class="testimonials-head">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-3 col-5">
                                <div class="testimonials-img">
                                    <img src="assets/images/testimonilas/t6.jpg" alt="testimonials">
                                </div>
                            </div>
                            
                            <div class="col-lg-9 col-md-9 col-7">
                                <div class="content">
                                    <h2>Keat Smith</h2>
                                    <span>Designer</span>
                                    <ul class="rate">
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                        <li> <i class="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="testimonials-foot">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-contact-us></app-contact-us>