import { Component } from '@angular/core';

@Component({
  selector: 'app-webdev',
  templateUrl: './webdev.component.html',
  styleUrls: ['./webdev.component.scss']
})
export class WebdevComponent {
  accordionItems = [
    {
        title: 'Quels sont les avantages du développement web sur mesure  ?',
        content: `Le développement web sur mesure permet d'obtenir une solution adaptée spécifiquement à vos besoins et objectifs. Cela garantit une meilleure performance, une expérience utilisateur optimale et une plus grande flexibilité par rapport aux solutions prêtes à l'emploi.`,
        open: false
    },
    {
        title: 'Comment garantissez-vous la qualité de votre développement web ?',
        content: `Nous suivons des normes strictes de qualité et de sécurité tout au long du processus de développement. De plus, nous effectuons des tests approfondis pour nous assurer que votre site web fonctionne correctement sur tous les navigateurs et appareils.`,
        open: false
    },
    {
        title: 'Quels types de clients servez-vous ?',
        content: `Nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
        open: false
    },
    {
        title: 'Offrez-vous des services de maintenance après le déploiement ?',
        content: `Oui, nous proposons des services de maintenance continue pour assurer que votre solution reste à jour, sécurisée et performante.`,
        open: false
    },
    {
        title: 'Combien de temps faut-il pour développer un site web ?',
        content: `La durée du développement dépend de la complexité du site web. Pour un site web de base, le processus peut prendre quelques semaines, tandis que pour un site web plus complexe, cela peut prendre plusieurs mois.`,
        open: false
    }
];

selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item){
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}
