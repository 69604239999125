import { Injectable } from '@angular/core';
import { SendEmailCommand, SESClient,} from "@aws-sdk/client-ses";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  SES_CONFIG = {
    region: 'us-east-1',
    credentials: {
      accessKeyId: environment.AWS_SES_ACCESS_KEY,
      secretAccessKey: environment.AWS_SES_SECRET_KEY
    }
}
client:any = new SESClient(this.SES_CONFIG);

  constructor() { }

  public sendMail(destinationEmail:string,subject:string,FormBody: { Text?: string, Html?: string }){
    let params = {
      Destination: {
        /* required */
        ToAddresses: [
         destinationEmail,
          /* more items */
        ],
      },
      Message: {
        /* required */
        Body: {
          ...(FormBody.Text && { Text: { Data: FormBody.Text, Charset: "UTF-8" } }),
          ...(FormBody.Html && { Html: { Data: FormBody.Html, Charset: "UTF-8" } }),
        },
        Subject: {
          Charset: "UTF-8",
          Data: `${subject}`,
        },
      },
      Source: "contact@nyangooagency.com" /* required */,
      ReplyToAddresses: [],
    };
    try {
      let command = new SendEmailCommand(params);
      const response = this.client.send(command);
      console.log(response);
    } catch (error) {
      console.log("Une erreur est survenue lors de l'envoie de l'email");
      console.log(error);
    }
  }
}
