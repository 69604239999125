import { Component } from '@angular/core';

@Component({
  selector: 'app-ia-services',
  templateUrl: './ia-services.component.html',
  styleUrls: ['./ia-services.component.scss']
})
export class IaServicesComponent {

  accordionItems = [
    {
        title:"Quels types de services d'IA offrez-vous ?",
        content: `Nous offrons une gamme de services d'IA, y compris le machine learning, le deep learning, le traitement du langage naturel (NLP), la vision par ordinateur, les chatbots et assistants virtuels, l'analyse prédictive, et l'automatisation des processus.`,
        open: false
    },
    {
        title: "Quels sont les avantages d'intégrer l'IA dans mon entreprise ?",
        content: `L'intégration de l'IA peut améliorer l'efficacité opérationnelle, réduire les coûts, augmenter la précision des prédictions, améliorer l'expérience client, et permettre de découvrir de nouvelles opportunités de marché.`,
        open: false
    },
    {
        title: 'Quels types de clients servez-vous ?',
        content: `Nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
        open: false
    },
    {
        title: 'Offrez-vous des services de maintenance après le déploiement ?',
        content: `Oui, nous proposons des services de maintenance continue pour assurer que votre solution reste à jour, sécurisée et performante.`,
        open: false
    },
    {
        title:"Combien de temps faut-il pour développer une solution d'IA ?",
        content: `Le temps nécessaire pour développer une solution d'IA dépend de la complexité du projet, des données disponibles, et des objectifs spécifiques. Cela peut varier de quelques semaines à plusieurs mois.`,
        open: false
    },
    {
      title:"Comment garantissez-vous la sécurité et la confidentialité de mes données ?",
      content: `Nous utilisons des protocoles de sécurité avancés et des pratiques de confidentialité strictes pour assurer la protection de vos données. Toutes les données sont cryptées et nous respectons les réglementations en vigueur pour la gestion des données.`,
      open: false
    },
    {
      title:"Quels types de technologies utilisez-vous pour vos projets d'IA ?",
      content: `Nous utilisons des technologies avancées telles que TensorFlow, PyTorch, scikit-learn, Keras, OpenCV, et les services cloud AI de AWS, Google Cloud, et Microsoft Azure pour développer des solutions d'IA performantes et scalables.`,
      open: false
    },
    {
      title:"Pouvez-vous intégrer l'IA dans nos systèmes existants ?",
      content: `Oui, nous pouvons intégrer des solutions d'IA dans vos systèmes existants. Nous effectuons une analyse approfondie de votre infrastructure actuelle pour assurer une intégration transparente et efficace.`,
      open: false
    },
    {
      title:" Offrez-vous des formations sur l'utilisation des solutions d'IA ?",
      content: `Oui, nous offrons des formations et un support continu pour vous aider à comprendre et à utiliser efficacement les solutions d'IA que nous développons pour votre entreprise.`,
      open: false
    },
];

selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item){
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}
