<div class="page-title-area-portfolio">
    <div class="container">
        <div class="page-title-content">
           <div class="row">
            <div class="col-12 col-lg-8">
                <span class="fs-4 text-white">DECOUVREZ NOTRE</span>
                <h2>services</h2>
                <h2 class="mt-4"><span class="p-3 fw-bold" style="background:#1d42d9;border-radius: 15px;">d'intelligence artificielle</span></h2>
                <br>
                <p>Plongez dans l'avenir avec nos services d'intelligence artificielle. Nous offrons des solutions innovantes et personnalisées qui tirent parti de la puissance de l'IA pour transformer votre entreprise. Que ce soit pour automatiser des tâches, analyser des données complexes ou améliorer l'expérience client, notre expertise en IA vous permet d'atteindre vos objectifs avec une efficacité sans précédent. Découvrez comment nous pouvons vous aider à rester à la pointe de la technologie et à dynamiser votre croissance.</p>
                <button class="btn btn-lg btn-primary d-lg-none" [routerLink]="['/project-form']">parlez nous de votre projet</button>
            </div>
            <div class="col-12 col-lg-4 d-none d-lg-inline">
                <div class="card" style="border-radius: 25px;">
                    <div class="card-body" >
                        <h4 class="card-title fs-4" style="color:#000;">Discutons de votre projet web</h4>
                        <app-contact-form></app-contact-form>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center" style="padding-bottom:120px;">
            <div class="col-lg-7 col-xxl-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Inteliggence artificielle</span>
                        <h2>Pourquoi L'ia pour votre projet ?</h2>
                        <p>L'intelligence artificielle (IA) représente une opportunité révolutionnaire pour votre projet. En intégrant des solutions d'IA, vous pouvez automatiser des tâches répétitives, améliorer la précision des décisions et offrir des expériences personnalisées à vos clients. L'IA permet d'analyser de grandes quantités de données rapidement, de prédire des tendances et d'optimiser vos opérations pour une efficacité accrue. En adoptant l'IA, vous positionnez votre entreprise à l'avant-garde de l'innovation, prêt à relever les défis de demain et à saisir de nouvelles opportunités de croissance.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-xxl-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ia-piano.jpg" style="border-radius:20px;" alt="company" />
                </div>
            </div>
        </div><!--item1-->
        <div class="row align-items-center mb-3">
            <div class="col-lg-5 col-xxl-6 col-md-12">
                <div class="company-img-2">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
            <div class="col-lg-7 col-xxl-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span class="pb-span">Problèmes</span>
                        <h4>Problèmes auxquels vous êtes confronté avec la plupart des sociétés de services D'IA</h4>
                        <p>Il est crucial de choisir le bon partenaire d'intelligence artificielle pour votre projet. Une entreprise de d'inteligence artificielle inadaptée peut engendrer de nombreux problèmes.</p>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="py-2"><img src="../../../../../assets/images/svg/budget2.svg" alt=""> <span class="px-2 pb-text">Budget excessif</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/database.svg" alt=""> <span class="px-2 pb-text">Données non sécurisée</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/communication.svg" alt=""> <span class="px-2 pb-text">Mauvaises communications</span></div>
                            </div>
                            
                            <div class="col-12 col-sm-6">
                                <div class="py-2"><img src="../../../../../assets/images/svg/quality.svg" alt=""> <span class="px-2 pb-text">Aucune assurance qualité</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/doc.svg" alt=""> <span class="px-2 pb-text">Manque de documentation</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/box.svg" alt=""> <span class="px-2 pb-text">Livrables retardés</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 2-->
    </div>
</section>

<!-- NYANGOO AGENCY HELP -->
<section class="help">
    <div class="container-md">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Comment Nyangoo company peut vous aider</h2>
            <p>Chez Nyangoo Agency, nous comprenons les défis auxquels vous pouvez être confrontés lors du choix d'un partenaire de développement web. C'est pourquoi nous nous engageons à offrir des solutions qui éliminent ces problèmes courants. Notre approche garantit une gestion budgétaire transparente et contrôlée, en veillant à ce que vos projets respectent vos contraintes financières.</p>
        </div>

       <div class="row row-cols-2 row-cols-sm-3">
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
           <div class="text-center">
            <img src="../../../../../assets/images/svg/feee-consultation.svg" class="pb-2 pt-4" alt="">
            <p style="color: #000;" class="fs-6 pt-2 pb-4">30 min de Consultation gratuite</p>
           </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/sign-nda.svg"class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">accord de confidentialité</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/dedicated-developer.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Développeurs et concepteurs internes</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/data-security.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Securite des donnees</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/feedback-cycle.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Interaction avec le client</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/regular-update.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Mise a jour reguliere</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/complate-transprency.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Transparence complte</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/quality-assurance.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Assurance qualite</p>
               </div>
        </div>
        <div class="col" >
            <div class="text-center">
                <img src="../../../../../assets/images/svg/maintanance-support.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Maintenance et support</p>
               </div>
        </div>
       </div>
    </div>
</section>

<!-- WEB SERVICES -->
<section id="web-services" class="web-services pt-5">
    <div class="container-lg">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Nos Services d'IA</h2>
            <p>Nous créons des sites vitrines attrayants et fonctionnels pour présenter votre entreprise et vos services en ligne. Chaque site est conçu pour refléter l'identité de votre marque et attirer des visiteurs.</p>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-4">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Analyse de données et prédiction</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Extraction et analyse de données pour identifier des tendances et faire des prévisions.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Automatisation des processus</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Automatisation des tâches répétitives pour augmenter l'efficacité et réduire les coûts.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Chatbots et assistants virtuels</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Développement de chatbots intelligents pour améliorer le service client et l'engagement.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Reconnaissance d'images</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Utilisation de l'IA pour identifier et analyser des objets, des visages et des scènes dans des images et des vidéos.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Traitement du langage naturel</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Développement d'applications capables de comprendre, interpréter et répondre au langage humain.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Systèmes de recommandation</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Création de systèmes qui proposent des produits, des services ou des contenus basés sur les préférences et le comportement des utilisateurs.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>

<!-- TRUSTED APP DEVELOPPEMENT COMPANY IN CAMEROUN -->
<section id="trusted-app" class="trusted-app">
    <div class="container-lg">
        <h2 class="text-center px-md-5 mx-md-5">Société de développement d'applications de confiance au Cameroun</h2>
    <br>
    <p class="text-center">Nous sommes fiers d'être une société de développement d'applications de confiance au Cameroun. Avec une équipe de professionnels passionnés et expérimentés, nous offrons des solutions digitales de haute qualité adaptées aux besoins spécifiques de nos clients. Que ce soit pour le développement de sites web, d'applications mobiles ou de logiciels de bureau, nous mettons un point d'honneur à livrer des produits performants, sécurisés et innovants. En travaillant avec nous, vous bénéficiez d'un partenariat basé sur la transparence, l'engagement et la satisfaction client. Faites-nous confiance pour transformer vos idées en solutions digitales efficaces et pérennes.</p>
    <div class="text-center mt-3">
        <img src="../../../../assets/images/laptop.webp" alt="">
    </div>
    </div>
</section><!-- END TRUSTED APP COMPANY-->

<!-- RECENT WEB PROJECT -->
<section id="recent" class="recent py-5">
    <div class="container-lg">
        <div class="text-center pb-4 pb-sm-3 pb-md-5">
            <span class="fs-4" style="text-transform:uppercase;filter: grayscale(100%);font-weight:bold;">Projets récents</span>
            <h1 class="why-choose-title">Quelques projets web récents</h1>
            <p style="font-size: 15px;">Découvrez notre portfolio et explorez les projets que nous avons réalisés pour nos clients. Chaque projet reflète notre engagement envers la qualité, l'innovation et la satisfaction client. Que ce soit des sites web, des applications mobiles ou des solutions d'IA, notre vitrine de travail démontre notre expertise et notre capacité à transformer vos idées en réalités digitales. Inspirez-vous de nos succès et imaginez ce que nous pouvons accomplir ensemble.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">Colorfol Digital store</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                </div>
                <h6>Colorfol est une plateforme camerounaise de promotions de la musique africaine partout dans le monde.</h6>
                <button class="btn btn-primary btn-lg"><a href="https://www.store.colorfol.com/home" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site <span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/colorfol.png" alt="">
            </div>
        </div> <!--item 1-->
        <hr>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/oneboutik.png" alt="">
            </div>
            <div class="col-12 col-md-6" >
                <h2 style="color:#000;">Oneboutik.com</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                </div>
                <!-- <h5 style="color:#000;">Solution web | e-commerce | Angular | Typescript | Javasript | Html | css | Nodejs </h5> -->
                <h6>oneboutik.com est une plateforme de e-commerce centralisant toutes les boutiques local d'une ville au même endroit, permettant aux clients d'acheter en ligne dans leur boutique favorites.</h6>
                <button class="btn btn-primary btn-lg"><a rel="noopener noreferrer" style="color:white;text-decoration: none;">Lire plus sur oneboutik.com <span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
        </div> <!-- item 2-->
        <hr>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">Jookafood</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                </div>
                <h6>jookafood est une application web permettant aux utilisateurs de commander des repas en ligne au Cameroun.</h6>
                <button class="btn btn-primary btn-lg"><a href="https://food.jookatechnologies.com/place-list" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/jookafood.png" alt="">
            </div>
        </div> <!-- item 3-->
    </div>
</section>

<app-testimonials-recent></app-testimonials-recent>

<!-- FAQ section -->
<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title-2">
            <span class="fs-4">FAQ</span>
            <h2>Question fréquemment posée</h2>
            <p>Nous avons gagné la confiance des gens grâce à notre excellent service</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-us></app-contact-us>

