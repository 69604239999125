<div class="page-title-area-portfolio">
    <div class="container">
        <div class="page-title-content">
           <div class="row">
            <div class="col-12 col-lg-8">
                <span class="fs-4 text-white">DECOUVREZ NOTRE</span>
                <h2>services de conception</h2>
                <h2 class="mt-4"><span class="p-3 fw-bold" style="background:#1d42d9;border-radius: 15px;">UI/UX</span></h2>
                <br>
                <p>Notre service de conception UI/UX est dédié à la création d'interfaces utilisateur intuitives et attrayantes qui offrent une expérience utilisateur exceptionnelle. Nous combinons une expertise technique approfondie avec une approche centrée sur l'utilisateur pour créer des designs qui captivent et convertissent. Que vous ayez besoin d'une refonte complète de votre interface utilisateur ou d'une conception à partir de zéro, notre équipe talentueuse est là pour répondre à vos besoins et donner vie à vos idées.</p>
                <button class="btn btn-lg btn-primary d-lg-none" [routerLink]="['/project-form']">parlez nous de votre projet</button>
            </div>
            <div class="col-12 col-lg-4 d-none d-lg-inline">
                <div class="card" style="border-radius: 25px;">
                    <div class="card-body" >
                        <h4 class="card-title fs-4" style="color:#000;">Discutons de votre projet web</h4>
                        <app-contact-form></app-contact-form>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center" style="padding-bottom:20px;">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Conception Ui/Ux</span>
                        <h2>Pourquoi un design pour votre projet?</h2>
                        <p>Le UI (Interface Utilisateur) et le UX (Expérience Utilisateur) jouent un rôle crucial dans la réussite de tout projet numérique. Une conception UI bien pensée garantit une interface esthétique et intuitive, tandis qu'une expérience utilisateur bien conçue assure une navigation fluide et agréable. En investissant dans le UI/UX, vous offrez à vos utilisateurs une expérience mémorable qui renforce la fidélité à la marque et stimule la croissance de votre projet.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/ui-ux.jpg" style="border-radius:20px;" alt="company" />
                </div>
            </div>
        </div><!--item1-->
    </div>
</section>

<!-- NYANGOO AGENCY HELP -->
<section class="help">
    <div class="container-md">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Comment Nyangoo company peut vous aider</h2>
            <p>Chez Nyangoo Agency, nous comprenons les défis auxquels vous pouvez être confrontés lors du choix d'un partenaire de développement web. C'est pourquoi nous nous engageons à offrir des solutions qui éliminent ces problèmes courants. Notre approche garantit une gestion budgétaire transparente et contrôlée, en veillant à ce que vos projets respectent vos contraintes financières.</p>
        </div>

       <div class="row row-cols-2 row-cols-sm-3">
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
           <div class="text-center">
            <img src="../../../../../assets/images/svg/feee-consultation.svg" class="pb-2 pt-4" alt="">
            <p style="color: #000;" class="fs-6 pt-2 pb-4">30 min de Consultation gratuite</p>
           </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/sign-nda.svg"class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">accord de confidentialité</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/dedicated-developer.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Développeurs et concepteurs internes</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/data-security.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Securite des donnees</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/feedback-cycle.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Interaction avec le client</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/regular-update.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Mise a jour reguliere</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/complate-transprency.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Transparence complte</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/quality-assurance.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Assurance qualite</p>
               </div>
        </div>
        <div class="col" >
            <div class="text-center">
                <img src="../../../../../assets/images/svg/maintanance-support.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Maintenance et support</p>
               </div>
        </div>
       </div>
    </div>
</section>


<!-- WEB SERVICES -->
<section id="web-services" class="web-services pt-5">
    <div class="container-lg">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Nos Services UI/UX</h2>
            <p>Chez Nyangoo Agency, nous offrons une gamme complète de services de conception UI/UX pour transformer vos idées en interfaces intuitives et attractives. De l’audit UX à l'optimisation continue, nous vous accompagnons à chaque étape pour garantir une expérience utilisateur exceptionnelle. Nos experts travaillent en étroite collaboration avec vous pour créer des designs qui captivent vos utilisateurs et reflètent fidèlement votre identité de marque.</p>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-4">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Audit UX</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Analyse approfondie de l'expérience utilisateur actuelle pour identifier les points forts et les axes d'amélioration.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Wireframing et Prototypage</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Création de maquettes basse et haute fidélité pour visualiser l'architecture et le flux de votre interface utilisateur.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Design d'Interface Utilisateur</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Conception graphique détaillée et attrayante des écrans de votre application mobile, de bureau ou site web.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Recherche Utilisateur</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Études et tests avec des utilisateurs réels pour comprendre leurs besoins et comportements en terme d'experience utilisateur.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Design Responsive</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Adaptation de l'interface pour une expérience optimale sur différents dispositifs (mobile, tablette, desktop).</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Tests d'Utilisabilité</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Évaluation de la facilité d'utilisation et de l'efficacité de l'interface à travers des tests utilisateurs.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Optimisation UI/UX Continue</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Améliorations régulières basées sur les retours des utilisateurs et les nouvelles tendances du design.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header"><h4 class="card-title">Design de Micro-Interactions</h4></div>
                        <div class="card-body">
                            <p class="card-text">Intégration d'animations et de transitions pour enrichir l'expérience utilisateur et rendre l'interface plus dynamique.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Accessibilité Web (A11Y)</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Conception d'interfaces accessibles à tous, y compris aux personnes en situation de handicap.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>

<!-- TRUSTED APP DEVELOPPEMENT COMPANY IN CAMEROUN -->
<section id="trusted-app" class="trusted-app">
    <div class="container-lg">
        <h2 class="text-center px-md-5 mx-md-5">Société de développement d'applications de confiance au Cameroun</h2>
    <br>
    <p class="text-center">Nous sommes fiers d'être une société de développement d'applications de confiance au Cameroun. Avec une équipe de professionnels passionnés et expérimentés, nous offrons des solutions digitales de haute qualité adaptées aux besoins spécifiques de nos clients. Que ce soit pour le développement de sites web, d'applications mobiles ou de logiciels de bureau, nous mettons un point d'honneur à livrer des produits performants, sécurisés et innovants. En travaillant avec nous, vous bénéficiez d'un partenariat basé sur la transparence, l'engagement et la satisfaction client. Faites-nous confiance pour transformer vos idées en solutions digitales efficaces et pérennes.</p>
    <div class="text-center mt-3">
        <img src="../../../../assets/images/laptop.webp" alt="">
    </div>
    </div>
</section><!-- END TRUSTED APP COMPANY-->

<!-- RECENT WEB PROJECT -->
<section id="recent" class="recent py-5">
    <div class="container-lg">
        <div class="text-center pb-3">
            <span class="fs-4" style="text-transform:uppercase;filter: grayscale(100%);font-weight:bold;">Projets récents</span>
            <h1 class="why-choose-title">Quelques projets récents</h1>
            <p style="font-size: 15px;">Découvrez notre portfolio et explorez les projets que nous avons réalisés pour nos clients. Chaque projet reflète notre engagement envers la qualité, l'innovation et la satisfaction client. Que ce soit des sites web, des applications mobiles ou des solutions d'IA, notre vitrine de travail démontre notre expertise et notre capacité à transformer vos idées en réalités digitales. Inspirez-vous de nos succès et imaginez ce que nous pouvons accomplir ensemble.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">Colorfol Digital store</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                </div>
                <h6>Colorfol est une plateforme camerounaise de promotions de la musique africaine partout dans le monde.</h6>
                <button class="btn btn-primary btn-lg"><a href="https://www.store.colorfol.com/home" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/colorfol.png" alt="">
            </div>
        </div> <!--item 1-->
        <hr>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/oneboutik.png" alt="">
            </div>
            <div class="col-12 col-md-6" >
                <h2 style="color:#000;">Oneboutik.com</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                </div>
                <!-- <h5 style="color:#000;">Solution web | e-commerce | Angular | Typescript | Javasript | Html | css | Nodejs </h5> -->
                <h6>oneboutik.com est une plateforme de e-commerce centralisant toutes les boutiques local d'une ville au même endroit, permettant aux clients d'acheter en ligne dans leur boutique favorites.</h6>
                <button class="btn btn-primary btn-lg"><a rel="noopener noreferrer" style="color:white;text-decoration: none;">Lire plus sur oneboutik.com <span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
        </div> <!-- item 2-->
        <hr>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">Jookafood</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Angular-js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Angular</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/typescript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Typescript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Javascript</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/HTML.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Html</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/css.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Css</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                </div>
                <h6>jookafood est une application web permettant aux utilisateurs de commander des repas en ligne au Cameroun.</h6>
                <button class="btn btn-primary btn-lg"><a href="https://food.jookatechnologies.com/place-list" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/jookafood.png" alt="">
            </div>
        </div> <!-- item 3-->
    </div>
</section>

<app-testimonials-recent></app-testimonials-recent>

<!-- WEB DEVELOPMENT PROCESS -->
<section id="web-development-process" class="web-development-process pb-5">
    <div class="container-lg">
     <div class="section-title-3">
         <h2>Processus de conception UI/UX</h2>
         <p>Le processus de conception UX/UI comprend plusieurs étapes clés, chacune étant cruciale pour garantir une expérience utilisateur optimale et une interface utilisateur attrayante.</p>
     </div>
 
     <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-3 pt-4">
         <div class="col">
             <div class="card">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">01</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Recherche utilisateur</h4>
                    <div class="card-text">
                        <ul>
                            <li>Études de marché</li>
                            <li>Analyse des concurrents</li>
                            <li>Création de personas</li>
                        </ul>
                    </div>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">02</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Objectifs et exigences</h4>
                     <div class="card-text">
                        <ul>
                            <li>Rédaction de scénarios d'utilisation</li>
                            <li>Définition des cas d'utilisation</li>
                            <li>Création de flux utilisateur</li>
                        </ul>
                    </div>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">03</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title"> Wireframing</h4>
                     <div class="card-text">
                        <ul>
                            <li>Dessin de wireframes</li>
                            <li>Organisation des éléments de l'interface</li>
                            <li>Validation de la structure</li>
                        </ul>
                    </div>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">04</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Prototypage</h4>
                     <div class="card-text">
                        <ul>
                            <li>Création de prototypes interactifs</li>
                            <li>Organisation des éléments de l'interface</li>
                            <li>Itération sur les prototypes</li>
                        </ul>
                    </div>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">05</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title"> Conception visuelle</h4>
                     <div class="card-text">
                        <ul>
                            <li>palettes de couleurs et de typographies</li>
                            <li>Définition du style graphique </li>
                            <li>Création de maquettes haute fidélité</li>
                        </ul>
                    </div>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">06</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Tests utilisateur</h4>
                     <div class="card-text">
                        <ul>
                            <li>Tests d'utilisabilité</li>
                            <li>Recueil des feedbacks utilisateur</li>
                            <li>Analyse des résultats et itérations</li>
                        </ul>
                    </div>
                 </div>
             </div>
         </div>
     </div>
    </div>
 </section>


 <!-- FAQ section -->
 <section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title-2">
            <span class="fs-4">FAQ</span>
            <h2>Question fréquemment posée</h2>
            <p>Nous avons gagné la confiance des gens grâce à notre excellent service</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-us></app-contact-us>