<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>FAQ</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">FAQ</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title-2">
            <span class="fs-5">Choissisez nyangoo agency</span>
            <h2>Question fréquemment posée</h2>
            <p>Nous avons gagné la confiance des gens grâce à notre excellent service.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-7 faq">
               <div class="mb-4">
                    <h2 class="category fw-bold" style="color:#000;">Services</h2>
                    <div class="choose-content">
                        <div class="faq-accordion">
                            <div class="faq-item" *ngFor="let item of servicesAccordionItems; let i = index;">
                                <div class="faq-header" (click)="toggleAccordionItem(item)">
                                    {{item.title}}
                                    <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                                </div>
                                <div class="faq-content" [hidden]="!item.open">
                                    <p>{{item.content}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
               </div><!-- Categ 1-->
               <div class="mb-4">
                <h2 class="category fw-bold" style="color:#000;">Processus</h2>
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of processAccordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
           </div><!-- Categ 2-->

                <div  class="mb-4">
                    <h2 class="category fw-bold" style="color:#000;">Tarification</h2>
                    <div class="choose-content">
                        <div class="faq-accordion">
                            <div class="faq-item" *ngFor="let item of tarificationAccordionItems; let i = index;">
                                <div class="faq-header" (click)="toggleAccordionItem(item)">
                                    {{item.title}}
                                    <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                                </div>
                                <div class="faq-content" [hidden]="!item.open">
                                    <p>{{item.content}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- Categ 3-->
                <div class="mb-4">
                    <h2 class="category fw-bold" style="color:#000;">Support et Garantie</h2>
                    <div class="choose-content">
                        <div class="faq-accordion">
                            <div class="faq-item" *ngFor="let item of supportAccordionItems; let i = index;">
                                <div class="faq-header" (click)="toggleAccordionItem(item)">
                                    {{item.title}}
                                    <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                                </div>
                                <div class="faq-content" [hidden]="!item.open">
                                    <p>{{item.content}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- Categ 4-->

                <div  class="mb-4">
                    <h2 class="category fw-bold" style="color:#000;">Collaboration</h2>
                    <div class="choose-content pb-70">
                        <div class="faq-accordion">
                            <div class="faq-item" *ngFor="let item of collaborationAccordionItems; let i = index;">
                                <div class="faq-header" (click)="toggleAccordionItem(item)">
                                    {{item.title}}
                                    <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                                </div>
                                <div class="faq-content" [hidden]="!item.open">
                                    <p>{{item.content}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- Categ 5-->
            </div>
        </div>
    </div>
</section>

<app-contact-us></app-contact-us>