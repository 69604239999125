<div class="page-title-area-portfolio">
    <div class="container">
        <div class="page-title-content">
           <div class="row">
            <div class=" col-12 col-lg-7">
                <ul class="mb-2">
                    <li><a routerLink="/">Home </a></li>
                    <li class="active">Testimonials</li>
                </ul>
                <span class="fs-4 text-white">DECOUVREZ</span>
                <h2>Nos solutons digitales pour nos clients</h2>
                <p>Explorez nos solutions digitales conçues pour répondre aux besoins spécifiques de nos clients. Que vous cherchiez à développer un site web, une application mobile, ou une solution d'intelligence artificielle, notre expertise et notre passion pour l'innovation garantissent des résultats exceptionnels. Découvrez comment nous pouvons transformer vos idées en réalisations concrètes et efficaces.</p>
                <div>
                    <button class="btn btn-lg consultation_btn" [routerLink]="['/project-form']">Obtenez une consultation gratuite</button>
                </div>
            </div>
            <div class="col-12 col-lg-5 d-none d-lg-inline">
                <img src="../../../../assets/images/sec1-img.webp" style="transform:translateY(-80px);" alt="">
            </div>
           </div>
        </div>
    </div>
</div>

<section class="projetcts" id="projects">
   <div class="container-xl">
    <div class="row align-items-center py-4">
        <div class="col-12 col-md-6">
            <img src="../../../../assets/images/projects/oneboutik.png" alt="">
        </div>
        <div class="col-12 col-md-6">
            <h1 style="color:#000;">Oneboutik.com</h1>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Type de solution : </span> <span class="fs-5" style="color: #000;">Applications web</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Technologie : </span> <span class="fs-5" style="color: #000;">Angular | NodeJS</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Categorie : </span> <span class="fs-5" style="color: #000;">Site de e-commerce</span></p>
            <!-- <h5 style="color:#000;">Solution web | e-commerce | Angular | Typescript | Javasript | Html | css | Nodejs </h5> -->
            <p style="color:black;">oneboutik.com est une plateforme de e-commerce centralisant toutes les boutiques local d'une ville au même endroit, permettant aux clients d'acheter en ligne dans leur boutique favorites.</p>
            <ul style="list-style-type:disc;font-size: 15px;color:#000;margin-left: 20px;">
                <li>Des millions d'articles en ligne</li>
                <li>Paiement en ligne sécurisé</li>
                <li>Prix compétitif</li>
            </ul>
            <br>
            <button class="btn btn-primary btn-lg"><a href="http://" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site internet <span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
        </div>
    </div> <!-- item 2-->
    <hr class="d-md-none">
    <div class="row align-items-center py-4">
        <div class="col-12 col-md-6">
            <h1 style="color:#000;">Colorfol Digital store</h1>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Type de solution : </span> <span class="fs-5" style="color: #000;">Applications web</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Technologie : </span> <span class="fs-5" style="color: #000;">Angular</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Categorie : </span> <span class="fs-5" style="color: #000;">Plateforme de streaming musical</span></p>
            <p style="color:black;">Colorfol est une plateforme camerounaise de promotions de la musique africaine partout dans le monde.</p>
            <ul style="list-style-type:disc;font-size: 15px;color:#000;margin-left: 20px;">
                <li>Lecture de millions de titres gratuit</li>
                <li>Achat d'album de vos artiste favoris</li>
                <li>Téléchargement hors ligne</li>
            </ul>
            <br>
            <button class="btn btn-primary btn-lg"><a href="https://www.store.colorfol.com/home" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site internet <span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
        </div>
        <div class="col-12 col-md-6">
            <img src="../../../../assets/images/projects/colorfol.png" alt="">
        </div>
    </div><!--item 1-->
    <hr class="d-md-none">

    <div class="row align-items-center py-4">
        <div class="col-12 col-md-6">
            <img src="../../../../assets/images/projects/jookafood.png" alt="">
        </div>
        <div class="col-12 col-md-6">
            <h1 style="color:#000;">Jookafood</h1>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Type de solution : </span> <span class="fs-5" style="color: #000;">Applications web</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Technologie : </span> <span class="fs-5" style="color: #000;">Angular</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Categorie : </span> <span class="fs-5" style="color: #000;">Plateforme de vente de repas en ligne</span></p>
            <p style="color:black;">jookafood est une application web permettant aux utilisateurs de commander des repas en ligne au Cameroun.</p>
            <ul style="list-style-type:disc;font-size: 19px;color:#000;margin-left: 20px;">
                <li>Commander des plats en ligne</li>
                <li>Livraison rapide</li>
                <li>Paiement a la livraison</li>
            </ul>
            <br>
            <button class="btn btn-primary btn-lg"><a href="https://food.jookatechnologies.com/place-list" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Lire plus sur jookafood <span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
        </div>
    </div> <!-- item 3-->
    <hr class="d-md-none">

    <div class="row align-items-center py-4">
        <div class="col-12 col-md-6" >
            <h1 style="color:#000;">Oisown suppliers</h1>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Type de solution : </span> <span class="fs-5" style="color: #000;">Applications android</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Technologie : </span> <span class="fs-5" style="color: #000;">Java | NodeJS | Elastic Search | Python</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Categorie : </span> <span class="fs-5" style="color: #000;">Application de gestion</span></p>
            <p style="color:black;">oisown suppliers est une applications permettant aux vendeurs et aux particuliers de gerer leurs produits en ligne.</p>
            <ul style="list-style-type:disc;font-size: 15px;color:#000;margin-left: 20px;">
                <li>Ajouter des produits</li>
                <li>Recevoir des commandes</li>
                <li>Consulter les statistiques</li>
            </ul>
        </div>
        <div class="col-12 col-md-6">
            <img src="../../../../assets/images/projects/postez.png"  alt="">
        </div>
    </div><!--item 4-->
    <hr class="d-md-none">

    <div class="row align-items-center py-4" >
        <div class="col-12 col-md-6">
            <img src="../../../../assets/images/projects/oisown-mobile.png"  alt="">
        </div>
        <div class="col-12 col-md-6">
            <h1 style="color:#000;">oisown e-commerce</h1>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Type de solution : </span> <span class="fs-5" style="color: #000;">Applications multiplatefome</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Technologie : </span> <span class="fs-5" style="color: #000;">Flutter</span></p>
            <p style="margin:0;padding:0"><span class="fw-bold fs-5" style="color:#000;">Categorie : </span> <span class="fs-5" style="color: #000;">Application de e-commerce</span></p>
            <p style="color:black;">oisown se-commerce est un site de e-commerce donnant aux particuliers et aux boutiques physiques une vitrine en ligne pour vendre leurs produits</p>
        </div>
       
    </div><!--item 4-->
    <hr class="d-md-none">
   </div>
</section>

<app-contact-us></app-contact-us>

