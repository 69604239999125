<div class="page-title-area-portfolio">
    <div class="container-lg">
        <div class="page-title-content">
                <div class="card" style="border-radius: 25px;max-width:35rem;">
                    <div class="card-body" >
                        <h4 class="card-title fs-2 text-start pt-2" style="color:#000;">Discutons de votre projet web</h4>
                        <div class="row pb-2">
                            <div class="col-auto"><span class="pe-2"><img src="../../../../assets/images/svg/check-icon.svg" alt=""></span><span>Tous nos projets sont sécurisés par contrat</span></div>
                            <div class="col-auto"><span class="pe-2"><img src="../../../../assets/images/svg/check-icon.svg" alt=""></span><span>100% sécurisé. Zéro spam.</span></div>
                        </div>
                        <form action="" [formGroup]="myForm">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 mb-2">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Votre nom" formControlName="name" [(ngModel)]="name" />
                                        <div class="text-start" *ngIf="myForm.get('name')?.hasError('required') && (myForm.get('name')?.dirty || myForm.get('name')?.touched)">
                                            <small style="color:red;font-size:12px;">Le nom est requis</small>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-sm-12 mb-2">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Votre email" formControlName="email" [(ngModel)]="email" />
                                        <div class="text-start" *ngIf="myForm.get('email')?.hasError('required') && (myForm.get('email')?.dirty || myForm.get('email')?.touched)">
                                            <small style="color:red;font-size:12px;">Le champs email est requis</small>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-sm-12 mb-2">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" formControlName="phone" placeholder="Votre numero de telephone" [(ngModel)]="phone" />
                                        <div class="text-start" *ngIf="myForm.get('phone')?.hasError('required') && (myForm.get('phone')?.dirty || myForm.get('phone')?.touched)">
                                            <small style="color:red;font-size:12px;">Le champs Numéro de téléphone est requis</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 mb-2">
                                    <div class="form-group">
                                        <textarea style="height:100px;" name="message" class="form-control" id="message" formControlName="message" cols="30" rows="2" required placeholder="Votre message" [(ngModel)]="message"></textarea>
                                        <div class="text-start" *ngIf="myForm.get('message')?.hasError('required') && (myForm.get('message')?.dirty || myForm.get('message')?.touched)">
                                            <small style="color:red;font-size:12px;">Le champs message est requis</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12 mb-2 text-start">
                                    <label style="text-align: start;">Selectionner le service</label>
                                    <select class="form-select form-control" style="border-radius:0;" aria-label="Default select example" formControlName ="selectedServices">
                                        <option value="Site internet">Site internet</option>
                                        <option value="Application mobile">Application mobile</option>
                                        <option value="Application web">Application web</option>
                                        <option value="Application de bureaux">Application de bureaux</option>
                                        <option value="Design UI/UX">Design UI/UX</option>
                                        <option value="Services D'IA">Services D'IA</option>
                                    </select>
                                </div>

                                <div class="col-lg-12 col-sm-12 mb-2">
                                    <div class="row">
                                        <div class="col-12 mb-2 mb-sm-0 col-sm-6 text-start">
                                            <label style="text-align:start;">Quand voulez vous commencer ?</label>
                                            <select class="form-select form-control" style="border-radius:0;" aria-label="Default select example" formControlName="startindDate">
                                                <option selected>Quand voulez vous commencer ?</option>
                                                <option value="Dès que possible">Dès que possible</option>
                                                <option value="Le weekend prochain">Le weekend prochain</option>
                                                <option value="Le mois prochain">Le mois prochain </option>
                                                <option value="Pas sure">Pas sure</option>
                                            </select>
                                        </div>

                                        <div class="col-12 col-sm-6 text-start">
                                            <label style="text-align:start;">Estimation du budget</label>
                                            <select class="form-select form-control" style="border-radius:0;" aria-label="Default select example" formControlName = "budget" [(ngModel)]="budget">
                                                <option value="50000 XAF - 99000 XAF">50000 XAF - 99000 XAF</option>
                                                <option value="100000 XAF - 299000 XAF">100000 XAF - 299000 XAF</option>
                                                <option value="000000 XAF - 500000 XAF">3000000 XAF - 500000 XAF</option>
                                                <option value="au dessus de 500000 XAF">au dessus de 500000 XAF</option>
                                                <option value="Pas sure">Pas sure</option>
                                            </select>

                                        </div>
                                    </div>
                                   
                                </div>

                                <div class="col-lg-12 col-sm-12 mb-2 text-start">
                                    <label style="text-align:start;">Comment vous contacter  ?</label>
                                    <select class="form-select form-control" formControlName="contact_method" style="border-radius:0;" aria-label="Default select example">
                                        <option selected>Comment vous contacter</option>
                                        <option value="Appelez moi">Appelez moi</option>
                                        <option value="Envoyer moi un email">Envoyer moi un email</option>
                                    </select>
                                </div>

                                
                               
    
                                <div class="col-lg-12 col-md-12 mb-2 d-grid mt-2">
                                    <button type="submit" class="default-btn  page-btn box-btn" [disabled]="name == '' || email == '' || phone == '' || message == '' " (click)="submit()">Envoyez votre message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
        </div>
    </div>
</div>
