import { Component } from '@angular/core';

@Component({
  selector: 'app-mobiledev',
  templateUrl: './mobiledev.component.html',
  styleUrls: ['./mobiledev.component.scss']
})
export class MobiledevComponent {

  accordionItems = [
    {
        title: 'Quelle est la différence entre une application native et une application hybride?',
        content: `Une application native est développée spécifiquement pour une plateforme (iOS ou Android) en utilisant les langages de programmation et outils natifs (comme Swift ou Kotlin). Une application hybride, en revanche, est construite en utilisant des technologies web (HTML, CSS, JavaScript) et peut fonctionner sur plusieurs plateformes via des frameworks comme React Native ou Ionic.`,
        open: false
    },
    {
        title: "Quels langages de programmation utilisez-vous pour le développement d'applications mobiles?",
        content: `Nous utilisons une variété de langages de programmation selon les besoins du projet. Pour les applications natives, nous utilisons Swift et Objective-C pour iOS, et Kotlin et Java pour Android. Pour les applications hybrides ou multiplateformes, nous utilisons des frameworks comme React Native et Flutter.`,
        open: false
    },
    {
        title: 'Quels types de clients servez-vous ?',
        content: `Nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
        open: false
    },
    {
        title: 'Offrez-vous des services de maintenance après le déploiement ?',
        content: `Oui, nous proposons des services de maintenance continue pour assurer que votre solution reste à jour, sécurisée et performante.`,
        open: false
    },
    {
        title: 'Combien de temps faut-il pour développer une application mobile? ?',
        content: `Le temps nécessaire pour développer une application mobile varie en fonction de la complexité du projet, des fonctionnalités requises, et des ressources disponibles. En général, le développement d'une application de base peut prendre entre 3 à 6 mois, tandis qu'une application plus complexe peut nécessiter 9 mois ou plus.`,
        open: false
    }
];

selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}
