<section class="home-contact-area home-2-contact ptb-100">
    <div class="container-lg">
        <div class="section-title-2">
            <span class="fs-4">Contactez nous</span>
            <h2>Faites-nous part de votre idée de projet !</h2>
            <p>Vous avez un projet en tête ou des questions sur nos services ? N'hésitez pas à nous contacter. Notre équipe est prête à vous écouter et à vous aider à transformer vos idées en réalité. Remplissez le formulaire ci-dessous ou appelez-nous directement. Nous avons hâte de collaborer avec vous !</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm" [formGroup]="myForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" formControlName = "name" required placeholder="Votre nom" />
                                    <div class="text-start" style="transform:translateY(-20px);" *ngIf="myForm.get('name')?.hasError('required') && (myForm.get('name')?.dirty || myForm.get('name')?.touched)">
                                        <small style="color:red;font-size:12px;">Le nom est requis</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" formControlName="email" required placeholder="Votre email" />
                                    <div class="text-start"  style="transform:translateY(-20px);" *ngIf="myForm.get('email')?.hasError('required') && (myForm.get('email')?.dirty || myForm.get('email')?.touched)">
                                        <small style="color:red;font-size:12px;">Le champs email est requis</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text"  name="phone_number" id="phone_number" required formControlName="phone" class="form-control" placeholder="Votre numero de telephone" />
                                    <div class="text-start"  style="transform:translateY(-20px);" *ngIf="myForm.get('phone')?.hasError('required') && (myForm.get('phone')?.dirty || myForm.get('phone')?.touched)">
                                        <small style="color:red;font-size:12px;">Le champs Numéro de téléphone est requis</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" formArrayName = "subject" class="form-control" required placeholder="Objet" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" formControlName="message" required placeholder="Votre message"></textarea>
                                    <div class="text-start"  style="transform:translateY(-20px);" *ngIf="myForm.get('message')?.hasError('required') && (myForm.get('message')?.dirty || myForm.get('message')?.touched)">
                                        <small style="color:red;font-size:12px;">Le champs message est requis</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" [disabled]="myForm.value.name == '' || myForm.value.email == '' || myForm.value.phone == '' || myForm.value.message == '' " (click)="submit()">
                                    <span *ngIf="send_email_loader == false">Submit</span>
                                    <span  *ngIf="send_email_loader == true">
                                        <div class="spinner-border text-light" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                          </div>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>