import { Component } from '@angular/core';

@Component({
  selector: 'app-desktop-dev',
  templateUrl: './desktop-dev.component.html',
  styleUrls: ['./desktop-dev.component.scss']
})
export class DesktopDevComponent {

  accordionItems = [
    {
        title: "Quelles plateformes de bureau supportez-vous pour le développement d'applications ?",
        content: `Nous développons des applications de bureau pour Windows, macOS et Linux, en fonction de vos besoins et de vos préférences.`,
        open: false
    },
    {
      
        title: "Quel est le processus de développement d'une application de bureau ?",
        content: `Notre processus de développement comprend plusieurs étapes : la collecte des exigences, la planification, le design UI/UX, le développement, les tests, et le déploiement. Nous assurons également la maintenance et les mises à jour après le lancement de l'application.`,
        open: false
    },
    {
        title: 'Quels types de clients servez-vous ?',
        content: `Nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
        open: false
    },
    {
        title: 'Offrez-vous des services de maintenance après le déploiement ?',
        content: `Oui, nous proposons des services de maintenance continue pour assurer que votre solution reste à jour, sécurisée et performante.`,
        open: false
    },
    {
        title: 'Combien de temps faut-il pour développer une application de bureau ?',
        content: `La durée de développement d'une application de bureau dépend de la complexité et des fonctionnalités requises. En général, un projet peut prendre entre 3 à 6 mois. Nous fournissons un calendrier détaillé après l'analyse de vos besoins.`,
        open: false
    }
];

selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item){
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}
