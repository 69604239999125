import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

    servicesAccordionItems = [
        {
            title: 'Quels services proposez-vous ?',
            content: `Nous offrons le développement de sites web, d'applications mobiles, d'applications de bureau, le développement d'API, la conception UI/UX, et des services d'IA.`,
            open: false
        },
        {
            title: 'Quels types de clients servez-vous ?',
            content: `Nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
            open: false
        },
        {
            title: 'Offrez-vous des services de maintenance après le déploiement ?',
            content: `Absolument. Nous nous spécialisons dans la création de solutions personnalisées adaptées aux besoins spécifiques de chaque client.`,
            open: false
        },
        {
            title: 'Pouvez-vous créer une solution sur mesure pour mon entreprise ?',
            content: `Combien de temps faut-il pour développer un site web ou une application ?.`,
            open: false
        }
    ];

    processAccordionItems = [
        {
            title: 'Comment démarrez-vous un nouveau projet ?',
            content: `Nous commençons par une analyse approfondie de vos besoins, suivie de la recherche et de la planification pour garantir un projet réussi.`,
            open: false
        },
        {
            title: 'Combien de temps faut-il pour développer un site web ou une application ?',
            content: `La durée dépend de la complexité du projet. Nous fournirons un calendrier détaillé après l'analyse des besoins..`,
            open: false
        },
        {
            title: 'Quels sont les étapes de votre processus de développement ?',
            content: `Nos étapes incluent l'analyse des besoins, la recherche et planification, le design UI/UX, le code et développement, les tests, et enfin le déploiement et maintenance.`,
            open: false
        },
    ];

    tarificationAccordionItems = [
        {
            title: 'Quels sont vos tarifs pour le développement web ?',
            content: `Nos tarifs varient en fonction des besoins spécifiques et de la complexité du projet. Contactez-nous pour un devis personnalisé.`,
            open: false
        },
        {
            title: 'Offrez-vous des forfaits ou des prix fixes pour certains services ?',
            content: `Oui, nous offrons des forfaits pour certains services standards. Pour des solutions sur mesure, nous proposons des devis détaillés après discussion de vos besoins.`,
            open: false
        },
        {
            title: 'Est-ce que les services de maintenance sont inclus dans le prix initial ?',
            content: `La maintenance est un service optionnel que nous offrons séparément, mais nous pouvons inclure un forfait de maintenance dans le contrat initial si vous le souhaitez.`,
            open: false
        },
    ];

    supportAccordionItems = [
        {
            title: "Comment puis-je obtenir de l'aide si j'ai un problème avec ma solution ?",
            content: `Vous pouvez nous contacter par e-mail, téléphone, ou via notre formulaire de contact en ligne. Notre équipe de support est là pour vous aider.`,
            open: false
        },
        {
            title: 'Proposez-vous une garantie sur vos services ?',
            content: `Oui, nous offrons une garantie sur nos services pour une période déterminée après le déploiement, pour nous assurer que tout fonctionne correctement.`,
            open: false
        },
        {
            title: 'Quelles sont vos heures de support ?',
            content: `Nous offrons un support pendant les heures de bureau standard, du lundi au vendredi, mais des arrangements spéciaux peuvent être pris pour des clients avec des besoins spécifiques.`,
            open: false
        },
    ];

    collaborationAccordionItems = [
        {
            title: "Travaillez-vous avec des entreprises de toutes tailles ?",
            content: `Oui, nous collaborons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
            open: false
        },
        {
            title: 'Comment puis-je collaborer avec votre équipe sur un projet commun ?',
            content: `Nous encourageons une communication ouverte et transparente. Nous utiliserons des outils de gestion de projet pour faciliter la collaboration et assurer le suivi du projet.`,
            open: false
        },
        {
            title: "Est-ce que vous travaillez avec des partenaires à l'international ?",
            content: `Oui, nous avons l'expérience de travailler avec des partenaires et clients à l'international, en adaptant nos horaires et méthodes de communication pour répondre à leurs besoins.`,
            open: false
        },
    ];


    selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}