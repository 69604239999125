import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/Services/email.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent {

  send_email_loader:boolean = false;


  myForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['',[Validators.required]],
    message: ['',[Validators.required]],
    selectedServices: [''],
    startindDate : [''],
    budget:[''],
    contact_method: ['']
  });


  //Form Variable - Observable

  name = "";
  email = "";
  phone = "";
  message = "";
  selectedServices = "";
  startindDate = "";
  contact_method = "";
  budget = "";

   

  

  

  constructor(
    private fb: FormBuilder,
    private _emailApi: EmailService,
    private toast: ToastrService
  ){

  }


  submit(){
    this.send_email_loader = true;
    console.log("Fonction d'envoie du message email");
    let body = {
      Html: 
       `
        <p>Email: ${this.email}</p>
        <p>Name:${this.name}</p>
        <p>phone number:${this.phone}</p>
        <p>Message : ${this.message}</p>
        <p>Selected Service: ${this.myForm.value.selectedServices}</p>
        <p>Starting date : ${this.myForm.value.startindDate}</p>
        <p>Methode de contact fournis: ${this.myForm.value.contact_method}</p>
        <p>Budget approximatif: ${this.myForm.value.budget}</p>
        `,
      Text: `Name:${this.name}. phone: ${this.phone}. message: ${this.message} . SelectedService : ${this.myForm.value.selectedServicess} ; Starting date : ${this.myForm.value.startindDate} ; Methode de contact fournis: ${this.myForm.value.contact_method} ; Budget : ${this.myForm.value.budget}`,
    }
    if (this.myForm.valid){
      console.log("My form All value",this.myForm.value);
      console.log(this.myForm.value.email);
      this._emailApi.sendMail("contact@nyangooagency.com","Soumission idee de projet",body);
      setTimeout(() => {
        this.send_email_loader = false;
        this.toast.success('Votre message a été transmis sans aucun problème. Merci beaucoup!', 'Email envoyé!');
      }, 2000);
    }else{
      this.send_email_loader = false;
      this.toast.error('please tosend mail, enter all the required fields', 'Email not sent!');
    }
  }


}
