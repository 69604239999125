<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo/agency-white.svg" style="height:60px;" alt="logo" /></a>
                    </div>
                    <p>Recevez des conseils exclusifs, des offres spéciales et des aperçus de nos projets directement dans votre boîte de réception. Rejoignez notre communauté et ne manquez aucune de nos mises à jour ! </p>

                    <div class="subscribe">
                        <h4>Rejoindre La Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Votre email" name="EMAIL" required autocomplete="off">
                            <button class="box-btn" type="submit">Souscrire</button>
                        </form>
                    </div>

                    <ul class="social">
                        <li><a href="https://www.facebook.com/profile.php?id=61554882189181&mibextid=ZbWKwL" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="https://www.instagram.com/nyangooagency?igsh=cmRzdTFnZTNpZjAz" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="https://x.com/nyangoo_agency?t=6Y0jR2MJHxI_SGMRhOi6bA&s=0" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Nos Services</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/mobile-development">Développement d'applications mobiles</a></li>
                        <li><a routerLink="/web-application-development">Développement d'applications webs</a></li>
                        <li><a routerLink="/desktop-development">Développement d'applications Windows et IOS</a></li>
                        <li><a routerLink="/web-application-development">Développement frontend</a></li>
                        <li><a routerLink="/backend-development">Développement d'api backend</a></li>
                        <li><a routerLink="/design-services">Conception de design UI/UX</a></li>
                        <li><a routerLink="/ia-services">Services d'intelligence artificielle</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Liens Rapide</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/testimonials">Services</a></li>
                        <li><a routerLink="/">Cariere</a></li>
                        <li><a routerLink="/privecy">Privacy & Policy</a></li>
                        <li><a routerLink="/terms-condition">Termes & Conditions</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+237657368904"><i class="bx bx-mobile-alt"></i> +237 657 368 904</a></li>
                        <li><a href="tel:+237675406055"><i class="bx bx-phone"></i> +237 675 406 055</a></li>
                        <li><a href="mailto:contact@nyangooagency.com"><i class="bx bxs-envelope"></i>contact@nyangooagency.com</a></li>
                        <li><a href="mailto:contact@nyangooagency.com"><i class="bx bxs-envelope"></i> support@nyangooagency.com</a></li>
                        <li><i class="bx bxs-map"></i> Rond point express, Yaounde, Cameroun</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Acceuil</a></li>
                        <li><a routerLink="/about">A propos</a></li>
                        <li><a routerLink="/solutions">Solutions</a></li>
                        <li><a routerLink="/case">Projects</a></li>
                        <!-- <li><a routerLink="/blog">Blog</a></li> -->
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>© {{company_name}} is Proudly Owned by <a>NYANGOO COMPANY SARL - Cameroun</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>