<section class="slider-area-2">
    <div class="home-slider">
        <owl-carousel-o [options]="homeSlides" class="d-none d-xl-inline">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Façonner le futur <span style="background-color:#1d42d9;border-radius:15px;" class="px-3">digital</span> avec créativité et innovation</h1>
                                            <p>Chez Nyangoo agency, nous repoussons les limites de la technologie pour donner vie à vos idées.Nos experts en développement web et mobile créent des solutions sur mesure qui allient esthétique et performance. Avec une touche de créativité et une dose d'innovation, nous transformons votre présence en ligne et vous aidons à conquérir le monde digital. Ensemble, construisons un avenir où vos rêves deviennent réalité.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/project-form" class="box-btn">Parlez nous de votre projet</a>
                                                <a routerLink="/about" class="box-btn border-btn">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Donnez vie à <span style="background-color:#1d42d9;border-radius:15px;" class="px-3">vos idées</span></h1>
                                            <p>Chez Nyangoo agency, nous transformons vos concepts en réalités digitales. Notre équipe de créateurs passionnés et de développeurs experts s'engage à concevoir des sites web et des applications mobiles sur mesure qui reflètent votre vision unique. Que vous ayez une idée révolutionnaire ou un projet créatif, nous mettons tout en œuvre pour la réaliser avec innovation et style. Avec nous, vos idées prennent vie et brillent dans le monde numérique.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/project-form" class="box-btn">Parlez nous de votre projet</a>
                                                <a routerLink="/about" class="box-btn border-btn">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-3">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Votre site, notre <span style="background-color:#1d42d9;border-radius:15px;" class="px-3">engagement</span> : ensemble vers le numérique.</h1>
                                            <p>Connecter l'Afrique au monde numérique : notre mission est d'offrir des services web de qualité qui autonomisent les entreprises, favorisent l'innovation et stimulent le développement économique.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/project-form" class="box-btn">Parlez nous de votre projet</a>
                                                <a routerLink="/about" class="box-btn border-btn">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>

        <!-- SHOW IN SMALL SCREEN  -->
        <owl-carousel-o [options]="homeSlides" class="d-inline d-xl-none">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Façonner le futur <span style="background-color:#1d42d9;border-radius:15px;" class="px-3">digital</span> avec créativité et innovation</h1>
                                            <p>Chez Nyangoo agency, nous repoussons les limites de la technologie pour donner vie à vos idées.Nos experts en développement web et mobile créent des solutions sur mesure qui allient esthétique et performance. Avec une touche de créativité et une dose d'innovation, nous transformons votre présence en ligne et vous aidons à conquérir le monde digital. Ensemble, construisons un avenir où vos rêves deviennent réalité.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/project-form" class="box-btn">Parlez nous de votre projet</a>
                                                <a routerLink="/about" class="box-btn border-btn">En savoir plus</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

    




<!-- TRUSTED BY THE BEST SECTION -->
<app-clients></app-clients>


<!-- SERVICE SECTION -->
<section class="home-service-area pb-70">
   <div class="container-xxl">
    <div class="row">
        <div class="col-12 col-xl-5">
            <div class="pt-5">
                <span style="text-transform: uppercase;color:white;" class="fs-3">decouvrez</span>
                <h2 class="service-title">Nos Services Digitaux de Première Classe</h2>
                <p style="color:white;font-size:large;">En tant que leader dans le domaine, nous sommes déterminés à offrir des services technologiques intelligents qui répondent aux besoins uniques de votre entreprise. Que vous cherchiez à établir une présence en ligne, à développer des applications personnalisées ou à optimiser votre infrastructure informatique, notre équipe d'experts dédiés est là pour vous accompagner à chaque étape du processus.</p>
            </div>

        </div>
        <div class="col-12 col-xl-7">
            <div class="row" style="padding-top:55px;">
                <div class="col-lg-6 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s1.png" alt="service" />
                        </div>
                        <div class="service-content">
                            <h3>Développement webs</h3>
                            <p>Création de sites web performants et sur mesure, alliant design moderne et fonctionnalités avancées.</p>
                            <a routerLink="/web-application-development" class="line-bnt">Lire plus</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s2.png" alt="service" />
                        </div>
    
                        <div class="service-content">
                            <h3>Développement mobiles</h3>
                            <p>Applications mobiles innovantes et sur mesure, offrant une expérience utilisateur fluide et engageante.</p>
                            <a routerLink="mobile-development" class="line-bnt">Lire plus</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s3.png" alt="service" />
                        </div>
    
                        <div class="service-content">
                            <h3>Applications de bureaux</h3>
                            <p>Développement d'applications de bureau sur mesure, performantes et intuitives pour toutes vos exigences.</p>
                            <a routerLink="desktop-development" class="line-bnt">Lire plus</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s4.png" alt="service" />
                        </div>
    
                        <div class="service-content">
                            <h3>Développement D'API</h3>
                            <p>Création d'API robustes et sécurisées pour faciliter l'intégration et améliorer les performances.</p>
                            <a routerLink="backend-development" class="line-bnt">Lire plus</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s5.png" alt="service" />
                        </div>
    
                        <div class="service-content">
                            <h3>Conception de design UI/UX</h3>
                            <p>Design UI/UX intuitif et attrayant pour une expérience utilisateur optimale et engageante.</p>
                            <a routerLink="design-services" class="line-bnt">Lire plus</a>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-6 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s6.png" alt="service" />
                        </div>
    
                        <div class="service-content">
                            <h3>Services d'IA</h3>
                            <p>Solutions d'IA avancées pour automatiser, optimiser et transformer vos processus métier.</p>
                            <a routerLink="ia-services" class="line-bnt">Lire plus</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
   </div>
</section><!-- END OF SERVICE SECTION-->

<!-- CHOOSE US SECTION -->
<section class="home2-choose-area pt-70 pb-70">
    <div class="container-lg">
        <div class="text-center">
            <span class="fs-4" style="text-transform:uppercase;filter: grayscale(100%);font-weight:bold;">Pourquoi nous choisir ?</span>
            <h2 class="why-choose-title">Choisissez nyangooagency:Votre chemin vers des solutions exceptionnelles</h2>
        </div>
        <div class="row pt-70 mb-3" >
            <div class="col-12 col-lg-7">
                <ul>
                    <li style="font-size: 16px;"><span class="fw-bold">Expertise et Innovation</span> : Notre équipe de développeurs et de designers expérimentés utilise les technologies les plus récentes pour créer des sites web, des applications mobiles et des solutions d'IA qui sont à la fois innovantes et performantes.</li>
                    <li style="font-size: 16px;"><span class="fw-bold">Approche Personnalisée</span> : Nous croyons en l'importance de comprendre vos objectifs et vos défis uniques. Chaque projet que nous entreprenons est sur mesure, conçu pour répondre parfaitement à vos besoins.</li>
                    <li style="font-size: 16px;"><span class="fw-bold">Engagement envers la Qualité</span> : La qualité est notre priorité. Nous mettons un point d'honneur à livrer des produits de haute qualité, testés et optimisés pour offrir une expérience utilisateur exceptionnelle.</li>
                    <li style="font-size: 16px;"><span class="fw-bold">Support Dédié</span> : Notre relation ne s'arrête pas à la livraison du projet. Nous offrons un support continu pour garantir que vos solutions fonctionnent de manière optimale et évoluent avec vos besoins.</li>
                    <li style="font-size: 16px;"><span class="fw-bold">Résultats Tangibles</span> : Nos solutions sont conçues pour obtenir des résultats concrets et mesurables, qu'il s'agisse d'augmenter votre visibilité en ligne, d'améliorer l'engagement des utilisateurs ou d'automatiser vos processus métiers.</li>
                </ul>
            </div>
            <div class="col d-sm-none d-lg-inline">
                <img src="../../../../assets/images/phone-mockup-3-removebg.png" alt="">

            </div>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-friends"></i>
                    </div>
                    <h3>Compréhension</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Meilleure qualité</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>
                    <h3>Technologie</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-customer-service"></i>
                    </div>
                    <h3>Support 24/7</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-like"></i>
                    </div>
                    <h3>Équipe d'experts</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Orienté prix</h3>
                </div>
            </div>
        </div>
    </div>
</section><!-- END OF CHOOSE US SECTION-->

<!-- RECENT PROJECT SECTION -->
<section id="recent" class="recent my-4">
    <div class="container-lg">
        <div class="text-center  pb-4 pb-sm-3 pb-md-5">
            <span class="fs-4" style="text-transform:uppercase;filter: grayscale(100%);font-weight:bold;">Projets récents</span>
            <h1 class="why-choose-title">Jetez un oeil à notre vitrine de travail</h1>
            <p style="font-size: 15px;">Découvrez notre portfolio et explorez les projets que nous avons réalisés pour nos clients. Chaque projet reflète notre engagement envers la qualité, l'innovation et la satisfaction client. Que ce soit des sites web, des applications mobiles ou des solutions d'IA, notre vitrine de travail démontre notre expertise et notre capacité à transformer vos idées en réalités digitales. Inspirez-vous de nos succès et imaginez ce que nous pouvons accomplir ensemble.</p>
        </div>
        <div class="row align-items-center py-0 py-sm-3 py-md-4">
            <div class="col-12 col-md-6 project-description">
                <h2 style="color:#000;">Colorfol Digital store</h2>
                <h5 style="color:#000;">Solution web | Angular | Typescript | Javasript | Html | css </h5>
                <h5>Colorfol est une plateforme camerounaise de promotions de la musique africaine partout dans le monde.</h5>
                <ul style="list-style-type:disc;font-size: 19px;color:#000;margin-left: 35px;">
                    <li>Lecture de millions de titres gratuit</li>
                    <li>Achat d'album de vos artiste favoris</li>
                    <li>Téléchargement hors ligne</li>
                </ul>
                <br>
                <button class="btn btn-primary btn-lg"><a href="https://www.store.colorfol.com/" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/colorfol.png" alt="">
            </div>
        </div> <!--item 1-->
        <hr>
        <div class="row align-items-center py-0 py-sm-3 py-md-4" >
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/oneboutik.png" alt="">
            </div>
            <div class="col-12 col-md-6 project-description">
                <h2 style="color:#000;">Oneboutik.com</h2>
                <h5 style="color:#000;">Solution web | e-commerce | Angular | Typescript | Javasript | Html | css | Nodejs </h5>
                <h5>oneboutik.com est une plateforme de e-commerce centralisant toutes les boutiques local d'une ville au même endroit, permettant aux clients d'acheter en ligne dans leur boutique favorites.</h5>
                <ul style="list-style-type:disc;font-size: 19px;color:#000;margin-left: 35px;">
                    <li>Des millions d'articles en ligne</li>
                    <li>Paiement en ligne sécurisé</li>
                    <li>Prix compétitif</li>
                </ul>
                <br>
                <button class="btn btn-primary btn-lg"><a href="/"  rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
        </div> <!-- item 2-->
        <hr>
        <div class="row align-items-center py-0 py-sm-3 py-md-4">
            <div class="col-12 col-md-6 project-description">
                <h2 style="color:#000;">Jookafood</h2>
                <h5 style="color:#000;">Solution web | Angular | Typescript | Javasript | Html | css | Nodejs | swagger </h5>
                <h5>jookafood est une application web permettant aux utilisateurs de commander des repas en ligne au Cameroun.</h5>
                <ul style="list-style-type:disc;font-size: 19px;color:#000;margin-left: 35px;">
                    <li>Commander des plats en ligne</li>
                    <li>Livraison rapide</li>
                    <li>Paiement a la livraison</li>
                </ul>
                <br>
                <button class="btn btn-primary btn-lg"><a href="https://food.jookatechnologies.com/" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Consulter le site<span class="material-icons" style="transform: translateY(6px);">east</span></a></button>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/jookafood.png" alt="">
            </div>
        </div> <!-- item 3-->
    </div>


</section>

<!-- TRUSTED APP DEVELOPPEMENT COMPANY IN CAMEROUN -->
<section id="trusted-app" class="trusted-app">
    <div class="container">
        <h2 class="text-center px-md-5 mx-md-5 trusted-company-title">Société de développement d'applications de confiance au Cameroun</h2>
    <br>
    <p>Nous sommes fiers d'être une société de développement d'applications de confiance au Cameroun. Avec une équipe de professionnels passionnés et expérimentés, nous offrons des solutions digitales de haute qualité adaptées aux besoins spécifiques de nos clients. Que ce soit pour le développement de sites web, d'applications mobiles ou de logiciels de bureau, nous mettons un point d'honneur à livrer des produits performants, sécurisés et innovants. En travaillant avec nous, vous bénéficiez d'un partenariat basé sur la transparence, l'engagement et la satisfaction client. Faites-nous confiance pour transformer vos idées en solutions digitales efficaces et pérennes.</p>
    <div class="text-center mt-5">
        <img src="../../../../assets/images/sec3-img.webp" alt="">
    </div>
    </div>
</section><!-- END TRUSTED APP COMPANY-->

<!-- TESTIMONIALS -->
<app-testimonials-recent></app-testimonials-recent>

<!-- WORKING PROCESS -->
<section id="web-development-process" class="web-development-process pb-5">
    <div class="container-lg">
     <div class="section-title-3">
         <h2>Processus de developpement</h2>
         <p>Chez Nyangoo Agency, notre processus de développement est conçu pour garantir une expérience transparente et efficace. Nous travaillons en étroite collaboration avec vous à chaque étape, de l'analyse initiale à la maintenance continue, pour créer des solutions web personnalisées qui répondent à vos besoins spécifiques.</p>
     </div>
 
     <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-3 pt-4">
         <div class="col">
             <div class="card process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">01</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Comprehension de vos objectifs</h4>
                     <p class="card-text">Nous démarrons en comprenant vos objectifs, travaillant ensemble pour saisir pleinement vos besoins, attentes et ambitions. Cette phase initiale définit clairement les objectifs du projet, établissant une base solide.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">02</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Analyse et planification</h4>
                     <p class="card-text">Après avoir compris vos objectifs, nous passons à l'analyse et à la planification. Cette étape détermine la portée du projet, identifie les exigences spécifiques et élabore une stratégie détaillée.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">03</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Design web</h4>
                     <p class="card-text">Notre équipe de designers crée des interfaces élégantes et intuitives qui captivent vos utilisateurs et sont fonctionnelles. Chaque élément est pensé pour refléter votre identité et offrir une expérience exceptionnelle.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">04</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Code et developpement</h4>
                     <p class="card-text"> Nos développeurs traduisent les concepts de conception en code fonctionnel, en utilisant les meilleures pratiques et les technologies les plus récentes. Nous suivons des normes strictes de qualité et de sécurité pour garantir que votre projet fonctionne de manière optimale et sans faille.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">05</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Test et assurance</h4>
                     <p class="card-text">Notre équipe de testeurs effectue des tests approfondis pour identifier et corriger les éventuels problèmes, en s'assurant que votre projet est entièrement fonctionnel et exempt de bugs. Nous nous engageons à fournir des produits de haute qualité qui répondent à vos besoins.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">06</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Deploiement et maintenance</h4>
                     <p class="card-text">Nous gérons le déploiement de manière professionnelle et efficace. Nous surveillons de près les performances, appliquons les mises à jour nécessaires et répondons à tout problème qui pourrait survenir. Notre objectif est de garantir que votre projet reste fonctionnel, sécurisé et à jour.</p>
                 </div>
             </div>
         </div>
 
     </div>
    </div>
 </section>

<!-- FAQ SECTION -->

<section class="choose-area ptb-100">
    <div class="container-md">
        <div class="section-title-2">
            <span class="fs-4">FAQ</span>
            <h2>Question fréquemment posée</h2>
            <p>Nous avons gagné la confiance des gens grâce à notre excellent service</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-us></app-contact-us>