<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title-2">
            <span class="fs-4">Testimonials</span>
            <h2>Ce que disent nos clients</h2>
            <p>La satisfaction de nos clients est notre priorité absolue. Découvrez ce qu'ils disent de leur expérience avec {{company_name}}. Nos clients apprécient notre engagement envers la qualité, notre réactivité et notre capacité à transformer leurs idées en réalités digitales. Leurs témoignages reflètent la confiance et la satisfaction qu'ils ont trouvées en collaborant avec nous. Laissez leurs mots vous convaincre de notre expertise et de notre dévouement à fournir des solutions exceptionnelles.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://lh3.googleusercontent.com/a-/ALV-UjUD1cbRfTNI7pR_9VqQuPtEcS_Z3S4tj8J_9WfYCs9oW9CUdL1u=s64-p-k-rw-no" style="width:60px;height:60px;" alt="img">
                        <p>Travailler avec {{company_name}} a été une expérience incroyable. Leur équipe a parfaitement compris ma vision et a créé un site web qui dépasse toutes mes attentes. Leur attention aux détails et leur professionnalisme m'ont vraiment impressionné. Je les recommande vivement à toute entreprise cherchant à améliorer sa présence en ligne.</p>
                        <h3>Saint germes</h3>
                        <span>Fondateur & CTO</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/anne.png" style="width:60px;height:60px;" alt="img">
                        <p>Nous avons fait appel à {{company_name}} pour développer notre application mobile, et nous n'avons pas été déçus. Leur créativité et leur expertise technique ont permis de transformer notre idée en une application fluide et attrayante. Le support continu qu'ils offrent est également un grand plus. Une équipe talentueuse et dévouée !</p>
                        <h3>Anne Marie</h3>
                        <span>Fondatrice de Start-Up</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="https://media.licdn.com/dms/image/D4E03AQH1vCckr0SWAg/profile-displayphoto-shrink_200_200/0/1691233162874?e=2147483647&v=beta&t=L781fWVvBt3XObYs674wxUTRkVt0SbIL7sg27H0YvfQ" style="width:60px;height:60px;" alt="img">
                        <p>{{company_name}} a joué un rôle clé dans la réussite de notre projet digital. Leur capacité à comprendre nos besoins et à proposer des solutions innovantes a fait toute la différence. Grâce à eux, notre site web est non seulement esthétique, mais aussi extrêmement fonctionnel. Nous continuerons à collaborer avec eux pour nos futurs projets.</p>
                        <h3>Marius TENE</h3>
                        <span>Entrepreuneur</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> 
