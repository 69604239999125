
<form action="" [formGroup]="myForm">
    <div class="row">
        <div class="col-lg-12 col-sm-12 mb-2">
            <div class="form-group">
                <input type="text" name="name" id="name" class="form-control" formControlName = "name" required placeholder="Votre nom" />
                <div class="text-start" *ngIf="myForm.get('name')?.hasError('required') && (myForm.get('name')?.dirty || myForm.get('name')?.touched)">
                    <small style="color:red;font-size:12px;">Le nom est requis</small>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-sm-12 mb-2">
            <div class="form-group">
                <input type="email" name="email" id="email" class="form-control" formControlName="email" required placeholder="Votre email" />
                <div class="text-start"  *ngIf="myForm.get('email')?.hasError('required') && (myForm.get('email')?.dirty || myForm.get('email')?.touched)">
                    <small style="color:red;font-size:12px;">Le champs email est requis</small>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-sm-12 mb-2">
            <div class="form-group">
                <input type="text" name="phone_number" id="phone_number" required class="form-control" formControlName="phone" placeholder="Votre numero de telephone" />
                <div class="text-start" *ngIf="myForm.get('phone')?.hasError('required') && (myForm.get('phone')?.dirty || myForm.get('phone')?.touched)">
                    <small style="color:red;font-size:12px;">Le champs Numéro de téléphone est requis</small>
                </div>
            </div>
        </div>

        <div class="col-lg-12 col-sm-12 mb-2">
            <div class="form-group">
                <input type="text" name="msg_subject" id="msg_subject" class="form-control" formControlName="subject" required placeholder="Objet" />
            </div>
        </div>

        <div class="col-lg-12 col-md-12 mb-2">
            <div class="form-group">
                <textarea style="height:100px;" name="message" class="form-control" id="message" cols="30" rows="2" required placeholder="Votre message" formControlName="message"></textarea>
                <div class="text-start"  *ngIf="myForm.get('message')?.hasError('required') && (myForm.get('message')?.dirty || myForm.get('message')?.touched)">
                    <small style="color:red;font-size:12px;">Le champs message est requis</small>
                </div>
            </div>
        </div>

        <button type="submit" class="default-btn page-btn box-btn" [disabled]="myForm.value.name == '' || myForm.value.email == '' || myForm.value.phone == '' || myForm.value.message == '' " (click)="submit()">
            <span *ngIf="send_email_loader == false">Envoyez votre message</span>
            <span  *ngIf="send_email_loader == true">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
            </span>
        </button>
    </div>
</form>