import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-home-two',
    templateUrl: './home-two.component.html',
    styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {
    company_name = "Nyangoo agency"

    brands = [
        {logo: "assets/images/client/colorfol.png"},
        {logo: "assets/images/client/oneboutik.png"},
        {logo: "assets/images/client/KHOLER.JPG"},
        {logo: "assets/images/client/Jooka-food.png"},
        {logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOyNVayUVhTEBnmgpyeG-EIBpCXvh8PgYT5lwlGm0wO_SgF8kMADSmqFKF63_NTaeL28Y&usqp=CAU"},
        {logo: "https://play-lh.googleusercontent.com/VIGeyC7-BpwkZcKje35DpO5cBtsKtvN4BFpYqP0hJzn2UbsWzJ7wguXwUobsHhufYXY=w240-h480-rw"},
       
        
    ]

    accordionItems = [
        {
            title: 'Quels services proposez-vous ?',
            content: `Nous offrons le développement de sites web, d'applications mobiles, d'applications de bureau, le développement d'API, la conception UI/UX, et des services d'IA.`,
            open: false
        },
        {
            title: 'Comment démarrez-vous un nouveau projet ?',
            content: `Nous commençons par une analyse approfondie de vos besoins, suivie de la recherche et de la planification pour garantir un projet réussi.`,
            open: false
        },
        {
            title: 'Quels types de clients servez-vous ?',
            content: `Nous travaillons avec des entreprises de toutes tailles, des start-ups aux grandes entreprises, dans divers secteurs.`,
            open: false
        },
        {
            title: 'Offrez-vous des services de maintenance après le déploiement ?',
            content: `Oui, nous proposons des services de maintenance continue pour assurer que votre solution reste à jour, sécurisée et performante.`,
            open: false
        },
        {
            title: 'Offrez-vous des services de maintenance après le déploiement ?',
            content: `Combien de temps faut-il pour développer un site web ou une application ?.`,
            open: false
        }
    ];

    selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }


    constructor() { }

    ngOnInit(): void {}

    homeSlides: OwlOptions = {
		animateOut: 'slideOutDown',
        animateIn: 'slideInDown',
        items: 1,
        loop: true,
        autoplay: true,
        dots: false,
        nav: true,
        autoHeight: true,
        autoplaySpeed: 800,
        mouseDrag: false,
        autoplayHoverPause: true,
        navText: [
            "<i class='flaticon-left-arrow'></i>", 
            "<i class='flaticon-next-1'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1200: {
                items: 1,
            }
        }
    }
    teamSlides: OwlOptions = {
		loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }
    
    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

	// Video Popup
	isOpen = false;
    openPopup(): void {
        this.isOpen = true;
    }
    closePopup(): void {
        this.isOpen = false;
    }

}