import { Component } from '@angular/core';

@Component({
  selector: 'app-design-services',
  templateUrl: './design-services.component.html',
  styleUrls: ['./design-services.component.scss']
})
export class DesignServicesComponent {

  accordionItems = [
    {
        title: "Qu'est-ce que la conception UI/UX",
        content: `La conception UI (Interface Utilisateur) concerne l'apparence et la disposition des éléments visuels sur une interface, tandis que la conception UX (Expérience Utilisateur) se concentre sur l'optimisation de l'interaction utilisateur avec le produit pour assurer une expérience fluide et agréable.`,
        open: false
    },
    {
        title: 'Pourquoi la conception UI/UX est-elle importante ?',
        content: `Une bonne conception UI/UX améliore l'engagement des utilisateurs, réduit les taux de rebond, et augmente la satisfaction et la fidélité des clients. Cela peut également conduire à une meilleure conversion et à un retour sur investissement plus élevé.`,
        open: false
    },
    {
        title: 'Quels services de conception UI/UX offrez-vous ?',
        content: `Nous offrons une gamme complète de services, y compris la recherche utilisateur, la création de wireframes, le prototypage, la conception visuelle, les tests utilisateur, et l'amélioration continue de l'expérience utilisateur.`,
        open: false
    },
    {
        title: 'Combien de temps prend un projet de conception UI/UX ?',
        content: `La durée d'un projet dépend de sa complexité et de son envergure. En général, un projet de conception UI/UX peut prendre de quelques semaines à plusieurs mois, incluant la recherche, la conception, les tests et les itérations.`,
        open: false
    },
    {
        title: 'Comment garantissez-vous que la conception répondra à nos besoins ?',
        content: `Nous commençons chaque projet par une phase de découverte approfondie pour comprendre vos objectifs et les besoins de vos utilisateurs. Nous impliquons également nos clients tout au long du processus pour garantir que les conceptions finales répondent à leurs attentes.`,
        open: false
    },
    {
      title: ' Utilisez-vous des outils spécifiques pour la conception UI/UX ?',
      content: ` Oui, nous utilisons une variété d'outils spécialisés comme Figma, Sketch, Adobe XD, InVision, et d'autres, pour créer des designs interactifs et collaboratifs.`,
      open: false
  },
  {
    title: 'Offrez-vous des services de tests utilisateur ?',
    content: `Oui, nous offrons des services de tests utilisateur pour évaluer l'efficacité de nos conceptions. Nous recueillons des feedbacks réels pour apporter les ajustements nécessaires et améliorer l'expérience utilisateur.`,
    open: false
  },
  {
  title: 'Puis-je voir des exemples de vos travaux de conception UI/UX ?',
  content: `Bien sûr ! Vous pouvez consulter notre portfolio sur notre site web pour voir des exemples de projets que nous avons réalisés pour d'autres clients.`,
  open: false
  },
  {
  title: 'Comment commence-t-on un projet de conception UI/UX avec vous ?',
  content: `Pour commencer, contactez-nous via notre formulaire de contact ou appelez-nous directement. Nous organiserons une consultation initiale pour discuter de vos besoins et définir la meilleure approche pour votre projet.`,
  open: false
  },
  {
    title: "Quel est le coût d'un projet de conception UI/UX ?",
    content: `Le coût varie en fonction de la complexité et de la portée du projet. Nous offrons des devis personnalisés après une analyse détaillée de vos exigences et objectifs.`,
    open: false
    }
];

selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item){
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }

}
