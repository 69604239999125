<section id="clients" class="clients">
    <div class="container-sm">
        <p class="text-center fs-4">LES  MEILLEURES NOUS FONT CONFIANCE</p>
    </div>
    <div class="container">
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-6 gy-3">
            <div class="col">
                <img src="assets/images/client/colorfol.png" alt="">
            </div>
            <div class="col">
                <img src="assets/images/client/oneboutik.png" alt="">
            </div>
            <div class="col">
                <img src="assets/images/client/KHOLER.JPG" alt="">
            </div>
            <div class="col">
                <img src="assets/images/client/Jooka_places.png" alt="">
            </div>
            <div class="col">
                <img style="height:80px;width:80px;transform:translateY(33px);" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOyNVayUVhTEBnmgpyeG-EIBpCXvh8PgYT5lwlGm0wO_SgF8kMADSmqFKF63_NTaeL28Y&usqp=CAU" alt="">
            </div>
            <div class="col">
                <img style="height:80px;width:80px;transform:translateY(33px);" src="https://play-lh.googleusercontent.com/VIGeyC7-BpwkZcKje35DpO5cBtsKtvN4BFpYqP0hJzn2UbsWzJ7wguXwUobsHhufYXY=w240-h480-rw" alt="">
            </div>
        </div>
        <div class="text-end p-3 d-none">
            <button class="btn btn-sm viewAllPartnersBtn" [routerLink]="['/partners']" >VIEW ALL PARTNERS</button>
        </div>
       </div>
</section>