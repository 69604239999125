<div class="page-title-area-portfolio">
    <div class="container">
        <div class="page-title-content">
           <div class="row">
            <div class="col-12 col-lg-8">
                <span class="fs-4 text-white">DECOUVREZ NOTRE</span>
                <h2>services de developpement </h2>
                <h2 class="mt-4"><span class="p-3 fw-bold" style="background:#1d42d9;border-radius: 15px;">d'applications mobiles</span></h2>
                <br>
                <p>Transformez vos idées en réalité grâce à nos services experts en développement d’applications mobiles. Nous sommes spécialisés dans la création de solutions innovantes qui stimulent la croissance et engagent efficacement les utilisateurs.</p>
                <button class="btn btn-lg btn-primary d-lg-none" [routerLink]="['/project-form']">parlez nous de votre projet</button>
            </div>
            <div class="col-12 col-lg-4 d-none d-lg-inline">
                <div class="card" style="border-radius: 25px;">
                    <div class="card-body" >
                        <h4 class="card-title fs-4" style="color:#000;">Discutons de votre projet web</h4>
                        <app-contact-form></app-contact-form>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center" style="padding-bottom:120px;">
            <div class="col-lg-7 col-xxl-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>developpement mobile</span>
                        <h2>Pourquoi le mobile pour votre projet ?</h2>
                        <p>Le développement mobile est essentiel pour atteindre vos clients là où ils passent la majorité de leur temps : sur leurs smartphones et tablettes. Avec une application mobile, vous pouvez offrir une expérience utilisateur personnalisée, améliorer l'engagement client et augmenter vos ventes. Les applications mobiles permettent une interaction directe avec vos utilisateurs, offrant des notifications en temps réel, des fonctionnalités hors ligne et une accessibilité accrue. En choisissant le mobile, vous positionnez votre entreprise à la pointe de la technologie, répondant aux attentes modernes et aux besoins de vos clients.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-xxl-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/why2.jpg" style="border-radius:20px;" alt="company" />
                </div>
            </div>
        </div><!--item1-->
        <div class="row align-items-center mb-3">
            <div class="col-lg-5 col-xxl-6 col-md-12">
                <div class="company-img-2">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
            <div class="col-lg-7 col-xxl-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span class="pb-span">Problèmes</span>
                        <h4>Problèmes auxquels vous êtes confronté avec la plupart des sociétés de développement mobiles</h4>
                        <p>Il est crucial de choisir le bon partenaire de développement mobile pour votre projet. Une entreprise de conception de d'applications mobile inadaptée peut engendrer de nombreux problèmes.</p>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="py-2"><img src="../../../../../assets/images/svg/budget2.svg" alt=""> <span class="px-2 pb-text">Budget excessif</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/database.svg" alt=""> <span class="px-2 pb-text">Données non sécurisée</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/communication.svg" alt=""> <span class="px-2 pb-text">Mauvaises communications</span></div>
                            </div>
                            
                            <div class="col-12 col-sm-6">
                                <div class="py-2"><img src="../../../../../assets/images/svg/quality.svg" alt=""> <span class="px-2 pb-text">Aucune assurance qualité</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/doc.svg" alt=""> <span class="px-2 pb-text">Manque de documentation</span></div>
                                <div class="py-2"><img src="../../../../../assets/images/svg/box.svg" alt=""> <span class="px-2 pb-text">Livrables retardés</span></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 2-->
    </div>
</section>

<!-- NYANGOO AGENCY HELP -->
<section class="help">
    <div class="container-md">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Comment Nyangoo company peut vous aider</h2>
            <p>Chez Nyangoo Agency, nous comprenons les défis auxquels vous pouvez être confrontés lors du choix d'un partenaire de développement mobile. C'est pourquoi nous nous engageons à offrir des solutions qui éliminent ces problèmes courants. Notre approche garantit une gestion budgétaire transparente et contrôlée, en veillant à ce que vos projets respectent vos contraintes financières.</p>
        </div>

       <div class="row row-cols-2 row-cols-sm-3">
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
           <div class="text-center">
            <img src="../../../../../assets/images/svg/feee-consultation.svg" class="pb-2 pt-4" alt="">
            <p style="color: #000;" class="fs-6 pt-2 pb-4">30 min de Consultation gratuite</p>
           </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/sign-nda.svg"class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">accord de confidentialité</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/dedicated-developer.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Développeurs et concepteurs internes</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/data-security.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Securite des donnees</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/feedback-cycle.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Interaction avec le client</p>
               </div>
        </div>
        <div class="col" style="border-bottom:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/regular-update.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Mise a jour reguliere</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/complate-transprency.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Transparence complte</p>
               </div>
        </div>
        <div class="col" style="border-right:2px dashed #ced4da;">
            <div class="text-center">
                <img src="../../../../../assets/images/svg/quality-assurance.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Assurance qualite</p>
               </div>
        </div>
        <div class="col" >
            <div class="text-center">
                <img src="../../../../../assets/images/svg/maintanance-support.svg" class="pb-2 pt-4" alt="">
                <p style="color: #000;" class="fs-6 pt-2 pb-4">Maintenance et support</p>
               </div>
        </div>
       </div>
    </div>
</section>

<!-- MOBILES SERVICES -->
<section id="web-services" class="web-services pt-5">
    <div class="container-lg">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Nos Services Mobiles</h2>
            <p>Nous offrons une gamme complète de services de développement mobile pour transformer vos idées en applications performantes et conviviales. Notre équipe d'experts crée des applications mobiles personnalisées pour iOS et Android, adaptées à vos besoins spécifiques et aux exigences de votre secteur.</p>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-4">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Développement d'Applications iOS</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Nous créons des applications iOS de haute qualité pour iPhone et iPad, en utilisant les dernières technologies et en respectant les directives d'Apple pour garantir une expérience utilisateur fluide et intuitive.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Développement d'Applications Android</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Nos experts en développement Android conçoivent des applications performantes et robustes, optimisées pour une large gamme d'appareils Android, offrant ainsi une expérience utilisateur cohérente et efficace.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Développement d'Applications Hybrides</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Nous utilisons des frameworks tels que React Native et Flutter pour créer des applications hybrides qui fonctionnent parfaitement sur iOS et Android, réduisant ainsi les coûts de développement et de maintenance.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Développement d'Applications Multiplateformes</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Avec des technologies comme Xamarin, nous développons des applications multiplateformes qui permettent de réutiliser le code sur plusieurs systèmes d'exploitation, assurant une cohérence et une efficacité maximales.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Design UI/UX Mobile</h4>
                        </div>
                        <div class="card-body">
                           
                            <p class="card-text">Notre équipe de designers crée des interfaces utilisateur attrayantes et intuitives, mettant l'accent sur une expérience utilisateur optimale et une navigation fluide, adaptée aux petites tailles d'écran des appareils mobiles.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Intégration de Fonctionnalités Avancées</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Nous intégrons des fonctionnalités avancées telles que les notifications push, la géolocalisation, les paiements en ligne, les chats en direct et plus encore, pour enrichir l'expérience utilisateur de votre application mobile.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Tests et Assurance Qualité Mobile</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Nous effectuons des tests rigoureux sur différentes plateformes et appareils pour nous assurer que votre application mobile est exempte de bugs, sécurisée et performante avant son lancement.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header"><h4 class="card-title">Maintenance et Support Mobile</h4></div>
                        <div class="card-body">
                            <p class="card-text">Après le lancement de votre application, nous offrons des services de maintenance et de support continus pour garantir que votre application reste à jour, sécurisée et performante.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Migration et Mise à Jour d'Applications Mobiles</h4>
                        </div>
                        <div class="card-body">
                            <p class="card-text">Nous aidons à la migration de vos anciennes applications vers de nouvelles plateformes ou versions, en assurant une transition en douceur et en mettant à jour les fonctionnalités pour répondre aux normes actuelles.</p>
                            <div class="text-end"><img src="../../../../../assets/images/code.png" style="height:35px;width:35px;" alt=""></div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>

<!-- TECHNOLOGIES STACK -->
<section id="technologies" class="technologies py-5">
    <div class="container-lg">
        <div class="section-title-2" style=" margin: -8px auto 30px;">
            <h2>Nos technologies mobiles</h2>
            <p>Ces technologies couvrent une large gamme de besoins et de préférences en développement mobile, Nous permettant de choisir les outils les mieux adaptés à vos projets.</p>
        </div>
        <div class="row">
            <div class="col-12 col-sm-3" style="color:#000;"><h3 class="fs-3">Applications Natives</h3></div>
            <div class="col-12 col-sm-9">
                <div class="row gy-2 py-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/swift.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Swift</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/kotlin.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Kotlin</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/java.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Java</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
        <hr>

        <div class="row py-3">
            <div class=" col-12 col-sm-3" style="color:#000;"><h3 class="fs-3">Applications Hybrides</h3></div>
            <div class=" col-12 col-sm-9">
                <div class="row gy-2 py-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/React-native.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">React Native</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/flutter.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Flutter</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
        <hr>
        <div class="row py-3">
            <div class="col-12 col-sm-4 col-lg-3" style="color:#000;"><h3 class="fs-3">multiplateforme</h3></div>
            <div class=" col-12 col-sm-8 col-lg-9">
                <div class="row gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/xamarin.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Xamarin</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/unity.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Unity</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
        <hr>
        <div class="row py-3">
            <div class=" col-12 col-sm-2" style="color:#000;"><h3 class="fs-3">Back-End</h3></div>
            <div class="col-12 col-sm-10">
                <div class="row gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/expressjs.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Express.js</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Django.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Django</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Flask.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Flask</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/ROR.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Ruby on Rails</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Microsoft_.NET_.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">ASP.NET</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
        <hr>
        <div class="row py-3">
            <div class="col-12 col-sm-3" style="color:#000;"><h3 class="fs-3">Bases de Données</h3></div>
            <div class="col-12 col-sm-9">
                <div class="row gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/MySQL.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">MySQL</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Postgresql_elephant.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">PostgreSQL</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/MongoDB.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">MongoDB</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Redis.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Redis</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/firebase.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Firebase</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--item 1-->
    </div>
</section>

<!-- TRUSTED APP DEVELOPPEMENT COMPANY IN CAMEROUN -->
<section id="trusted-app" class="trusted-app">
    <div class="container-lg">
        <h2 class="text-center px-md-5 mx-md-5 trusted-company-title">Société de développement d'applications de confiance au Cameroun</h2>
    <br>
    <p class="text-center">Nous sommes fiers d'être une société de développement d'applications de confiance au Cameroun. Avec une équipe de professionnels passionnés et expérimentés, nous offrons des solutions digitales de haute qualité adaptées aux besoins spécifiques de nos clients. Que ce soit pour le développement de sites web, d'applications mobiles ou de logiciels de bureau, nous mettons un point d'honneur à livrer des produits performants, sécurisés et innovants. En travaillant avec nous, vous bénéficiez d'un partenariat basé sur la transparence, l'engagement et la satisfaction client. Faites-nous confiance pour transformer vos idées en solutions digitales efficaces et pérennes.</p>
    <div class="text-center mt-3">
        <img src="../../../../assets/images/developing.webp" alt="">
    </div>
    </div>
</section><!-- END TRUSTED APP COMPANY-->


<!-- RECENT WEB PROJECT -->
<section id="recent" class="recent py-5">
    <div class="container-lg">
        <div class="text-center pb-3">
            <span class="fs-4" style="text-transform:uppercase;filter: grayscale(100%);font-weight:bold;">Projets récents</span>
            <h1 class="why-choose-title">Quelques projets mobiles récents</h1>
            <p style="font-size: 15px;">Découvrez notre portfolio et explorez les projets que nous avons réalisés pour nos clients. Chaque projet reflète notre engagement envers la qualité, l'innovation et la satisfaction client. Que ce soit des sites web, des applications mobiles ou des solutions d'IA, notre vitrine de travail démontre notre expertise et notre capacité à transformer vos idées en réalités digitales. Inspirez-vous de nos succès et imaginez ce que nous pouvons accomplir ensemble.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">oisown suppliers</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/java.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Java</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Nodejs</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/firebase.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Firebase</span>
                        </div>
                    </div>
                </div>
                <h6>oisown suppliers est une applications permettant aux vendeurs et aux particuliers de gerer leurs produits en ligne.</h6>
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/postez.png" alt="">
            </div>
        </div> <!--item 1-->
        <hr class="d-xl-none">
       
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/oisown-mobile.png" alt="">
            </div>
            <div class="col-12 col-md-6" >
                <h2 style="color:#000;">Oneboutik.com mobile</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/flutter.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">flutter</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/firebase.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Firebase</span>
                        </div>
                    </div>
                </div>
                <!-- <h5 style="color:#000;">Solution web | e-commerce | Angular | Typescript | Javasript | Html | css | Nodejs </h5> -->
                <h6>oneboutik.com est une plateforme de e-commerce centralisant toutes les boutiques local d'une ville au même endroit, permettant aux clients d'acheter en ligne dans leur boutique favorites.</h6>
                <!-- <button class="btn btn-primary btn-lg d-none d-md-inline"><a href="http://" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Lire plus sur oneboutik.com <span class="material-icons" style="transform: translateY(6px);">east</span></a></button> -->
            </div>
        </div> <!-- item 2-->
        <hr class="d-xl-none">
        
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h2 style="color:#000;">Jookafood</h2>
                <div class="row pt-2 pb-3 gy-2">
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/kotlin.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">kotlin</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Node.js.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Node.js</span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div style="background-color:#e9ecef;border-radius:15px;" class="px-3 py-1">
                            <img src="../../../../../assets/images/svg/Javascript.svg" alt=""> <span class="fs-6 ps-2" style="color: #000;">Firebase</span>
                        </div>
                    </div>
                </div>
                <h6>jookafood est une application mobile permettant aux utilisateurs de commander des repas en ligne au Cameroun.</h6>
                <!-- <button class="btn btn-primary btn-lg d-none d-md-inline"><a href="http://" target="_blank" rel="noopener noreferrer" style="color:white;text-decoration: none;">Lire plus sur jookafood <span class="material-icons" style="transform: translateY(6px);">east</span></a></button> -->
            </div>
            <div class="col-12 col-md-6">
                <img src="../../../../assets/images/projects/jooka.png" alt="">
            </div>
        </div> <!-- item 3-->
    </div>
</section>

<app-testimonials-recent></app-testimonials-recent>

<!-- WEB DEVELOPMENT PROCESS -->
<section id="web-development-process" class="web-development-process pb-5">
    <div class="container-lg">
     <div class="section-title-3">
         <h2>Processus de developpement mobile</h2>
         <p>Chez Nyangoo Agency, notre processus de développement web est conçu pour garantir une expérience transparente et efficace. Nous travaillons en étroite collaboration avec vous à chaque étape, de l'analyse initiale à la maintenance continue, pour créer des solutions web personnalisées qui répondent à vos besoins spécifiques.</p>
     </div>
 
     <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-3 pb-3 pt-4">
         <div class="col">
             <div class="card process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">01</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Comprehension de vos objectifs</h4>
                     <p class="card-text">Nous démarrons en comprenant vos objectifs, travaillant ensemble pour saisir pleinement vos besoins, attentes et ambitions. Cette phase initiale définit clairement les objectifs du projet, établissant une base solide.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">02</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Analyse et planification</h4>
                     <p class="card-text">Après avoir compris vos objectifs, nous passons à l'analyse et à la planification. Cette étape détermine la portée du projet, identifie les exigences spécifiques et élabore une stratégie détaillée.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-1">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">03</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Design mobile UI/UX</h4>
                     <p class="card-text">Notre équipe de designers crée des interfaces élégantes et intuitives qui captivent vos utilisateurs et sont fonctionnelles. Chaque élément est pensé pour refléter votre identité et offrir une expérience exceptionnelle.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">04</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Code et developpement</h4>
                     <p class="card-text"> Nos développeurs traduisent les concepts de conception en code fonctionnel, en utilisant les meilleures pratiques et les technologies les plus récentes. Nous suivons des normes strictes de qualité et de sécurité pour garantir que votre projet fonctionne de manière optimale et sans faille.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">05</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Test et assurance</h4>
                     <p class="card-text">Notre équipe de testeurs effectue des tests approfondis pour identifier et corriger les éventuels problèmes, en s'assurant que votre projet est entièrement fonctionnel et exempt de bugs. Nous nous engageons à fournir des produits de haute qualité qui répondent à vos besoins.</p>
                 </div>
             </div>
         </div>
         <div class="col">
             <div class="card process-card card-part-2">
                 <div class="card-header">
                     <h2 class="card-title" style="font-size: 50px;margin-bottom: 0;">06</h2>
                 </div>
                 <div class="card-body">
                     <h4 class="card-title">Deploiement et maintenance</h4>
                     <p class="card-text">Nous gérons le déploiement de manière professionnelle et efficace. Nous surveillons de près les performances, appliquons les mises à jour nécessaires et répondons à tout problème qui pourrait survenir. Notre objectif est de garantir que votre projet reste fonctionnel, sécurisé et à jour.</p>
                 </div>
             </div>
         </div>
 
     </div>
    </div>
 </section>

 <!-- FAQ section -->
 <section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title-2">
            <span class="fs-4">FAQ</span>
            <h2>Question fréquemment posée</h2>
            <p>Nous avons gagné la confiance des gens grâce à notre excellent service</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-us></app-contact-us>