<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Acceuil </a></li>
                <li class="active">A propros</li>
            </ul>
            <h2>A propos de {{company_name}}</h2>
            <p>Une société innovante de développement d'applications Web et mobile de confiance basée à yaounde, au Cameroun</p>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>


<section class="home-company-area">
    <div class="container-md">
        <div class="row align-items-center mb-3">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Notre entreprise</span>
                        <h2>Qui nous sommes </h2>
                        <p>Chez {{company_name}}, nous sommes passionnés par la technologie et l'innovation. Fondée par une équipe de professionnels dévoués et expérimentés, notre agence se spécialise dans le développement de solutions digitales sur mesure.</p>
                        <p>Avec une expertise couvrant le développement de sites web, d'applications mobiles, d'applications de bureau, de développement d'API, de conception UI/UX, et de services d'IA, nous sommes prêts à relever tous les défis. Nous nous engageons à offrir des produits de haute qualité, performants et sécurisés.</p>
                        <p>La satisfaction de nos clients est notre priorité. Nous mettons un point d'honneur à établir des relations de confiance, basées sur la transparence, la collaboration et l'excellence. Découvrez pourquoi tant de clients nous font confiance pour leurs projets digitaux.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
        <!-- mission,vison,goal -->
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gy-2">
            <div class="col">
                <div class="single-service" style="padding:15px;">
                    <div class="service-img">
                        <img src="assets/images/mission.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3>Notre mission</h3>
                        <p>Offrir des services web de qualité qui autonomisent les entreprises, favorisent l'innovation et stimulent le développement économique.</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="single-service" style="padding:15px;">
                    <div class="service-img">
                        <img src="assets/images/vision-2.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3>Notre vision</h3>
                        <p>Devenir un leader technologique mondial, en offrant des solutions web innovantes qui préparent les entreprises aux défis de demain</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="single-service" style="padding:20px;">
                    <div class="service-img">
                        <img src="assets/images/valeur.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3>Nos valeurs</h3>
                        <p>Innovation, qualité, transparence, collaboration et satisfaction client guident chacune de nos actions et décisions.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- PROBLEME AND SOLUTION -->
<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/flex-copy.jpg" alt="choose"/>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Problème et solutions</h2>
                    <p>En constatant que toutes les entreprises dans les autres continents, qu'elles soient petites ou grandes, possèdent au moins un site web et/ou une application pour la partie digital de leurs business, Nos fondateurs ont réalisé qu'en Afrique, de nombreuses entreprises ne bénéficiaient pas de cette présence en ligne. Ce constat a inspiré la création de notre agence web. Notre objectif est de permettre à toutes les entreprises en Afrique d'avoir une visibilité en ligne grâce à des sites internet et des applications adaptés à leurs besoins.</p>
                    <p>Face à cette disparité, notre agence web se positionne comme un catalyseur du développement numérique en Afrique. Nous offrons des services de création de sites internet et d'applications(mobiles, bureaux etc..), qu'il s'agisse de petites entreprises locales ou de grandes entreprises cherchant à étendre leur présence en ligne. Notre engagement est de fournir des solutions web innovantes et personnalisées pour répondre aux besoins spécifiques de chaque client.</p>
                    <a routerLink="/project-form" class="box-btn">Parlons de votre projet maintenant</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- TEAM MEMBER -->
<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title-2">
            <span class="fs-4">L'equipe executif</span>
            <h2>Les personnes qui sont derrière les réalisations</h2>
            <p>Découvrez l'équipe talentueuse et dévouée qui donne vie à vos projets. Chaque membre de notre équipe apporte une expertise unique, un dévouement sans faille et une passion pour l'innovation. Ensemble, nous travaillons en synergie pour vous offrir des solutions digitales de qualité, adaptées à vos besoins spécifiques. Nous sommes fiers de notre équipe et de tout ce que nous réalisons ensemble pour vous.</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="../../../../assets/images/team/me2.jpeg" style="height:300px;object-fit:cover;object-position:top;border-top-left-radius:15px;border-top-right-radius:15px;" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>YANNICK-JOSEPH MBIDA</h3>
                            <p>Chief executive officer | CEO</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="../../../../assets/images/team/g2.jpeg" style="height:300px;object-fit:cover;object-position:top;border-top-left-radius:15px;border-top-right-radius:15px;" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>ROCHNEL TEGOMO</h3>
                            <p>Chief Technologie Officer | CTO</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="../../../../assets/images/team/g1.jpeg" style="height:300px;object-fit:cover;border-top-left-radius:15px;border-top-right-radius:15px;" alt="team" />
        
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>EMMANUEL KAMENI</h3>
                            <p>Chief Design Officer | CDO</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<!-- TRUSTED CLIENTS SECTION -->
<div style="background-color:rgb(245, 245, 245);">
    <app-clients></app-clients>
</div>



<app-contact-us></app-contact-us>


